<script setup lang="ts">const outerClass = __MACROS_toRef(__props, "class");

import { toRef as __MACROS_toRef } from "vue";
import { ref, computed, nextTick } from 'vue'
import type { FormKitNode } from '@formkit/core'
import { FormKitIcon } from '@formkit/vue'
import useLocales from '@composable/useLocales'

export interface Props {
  modelValue?: string
  label?: string
  validationLabel?: string
  placeholder?: string
  validation?: string
  noDefaultValidation?: boolean
  help?: string
  errors?: string[]
  email?: boolean
  number?: boolean
  search?: boolean
  date?: boolean
  // datetime?: boolean // not supported for now due to bug in FormKit 0.19
  time?: boolean
  password?: boolean
  url?: boolean
  disabled?: boolean
  required?: boolean
  noIcon?: boolean
  icon?: string
  class?: string
  embedded?: boolean
  readonly?: boolean
  autofocus?: boolean
  min?: string
  cardSearch?: boolean
  noSearchSpinner?: boolean
}

const { LL } = useLocales()

withDefaults(defineProps<Props>(), { modelValue: '',label: '',validationLabel: null,placeholder: '',validation: '',noDefaultValidation: false,help: '',errors: [],email: false,number: false,search: false,date: false,time: false,password: false,url: false,disabled: false,required: false,noIcon: false,autofocus: false,cardSearch: false,noSearchSpinner: false, })

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'node', value: FormKitNode): void
  (e: 'onkeyEnter'): void
  (e: 'focus'): void
  (e: 'blur'): void
}>()

const showPassword = ref(false)

type TextFieldType = 'text' | 'email' | 'password' | 'number' | 'search' | 'date' | 'time' | 'url'

const inputType = computed<TextFieldType>(() => {
  if (__props.email) return 'email'
  if (__props.number) return 'number'
  if (__props.search) return 'search'
  if (__props.date) return 'date'
  if (__props.time) return 'time'
  if (__props.password) {
    return showPassword.value ? 'text' : 'password'
  }
  if (__props.url) return 'url'
  return 'text'
})

const prefixIcon = computed(() => {
  if (__props.noIcon) return ''
  if (__props.icon) return __props.icon
  if (__props.email) return 'email'
  if (__props.search) return 'search'
  if (__props.password) return 'password'
  // TODO: more to come
  return ''
})

const computedValidation = computed(() => {
  if (__props.noDefaultValidation) return __props.validation

  const toValidate = []
  if (__props.required) toValidate.push('required')
  if (__props.email) toValidate.push('email')
  if (__props.number) toValidate.push('number')
  // TODO: more to come

  toValidate.push(__props.validation) // additional given validation rules
  return toValidate.join('|')
})

const focussed = ref(false)

const showPasswordIcon = computed(() => {
  return showPassword.value ? 'eyeClosed' : 'eye'
})

const showPasswordTitle = computed(() => {
  return showPassword.value ? LL.value.login.passwordHide() : LL.value.login.passwordShow()
})

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value
}

function gatherNode(node: FormKitNode) {
  emit('node', node)
  // focussing the element needs to be deferred to make sure, everything is rendered
  nextTick(() => {
    // moved the conditional inside, to make typescript happy
    if (__props.autofocus && node.props.id) {
      const el = document.getElementById(node.props.id)
      if (el) el.focus() // no better way to do this with formkit, right now :(
    }
  })
}
const isSearchSpinner = ref(false)
function handleInput(value: any) {
  isSearchSpinner.value = __props.search && !__props.noSearchSpinner
  emit('update:modelValue', value)
  setTimeout(() => {
    isSearchSpinner.value = false
  }, 200)
}
</script>

<template>
  <FormKit
    :type="inputType"
    :inner-class="`relative flex justify-center items-center ${
      embedded ? 'border-none' : 'bg-white dark:bg-black border rounded-lg'
    } ${focussed ? 'border-blue dark:border-blue' : 'border-gray-border dark:border-gray-dark'} ${
      cardSearch ? 'border-gray/20' : ''
    }`"
    :input-class="`w-full px-2 py-2 rounded-lg text-base text-black dark:text-white ${
      embedded ? 'bg-white/0' : 'bg-white dark:bg-black'
    } ${readonly ? 'cursor-default' : ''} outline-none invalid:bg-red/10`"
    label-class="text-sm text-gray-dark dark:text-gray-light"
    :prefix-icon-class="{ 'w-6 ml-2 transition': prefixIcon, 'text-blue': focussed }"
    message-class="text-red"
    help-class="text-gray"
    :validation-label="validationLabel ?? label"
    v-bind="{ label, placeholder, help, errors, disabled, prefixIcon, outerClass, readonly, min }"
    :validation="computedValidation"
    :modelValue="modelValue"
    @input="handleInput($event)"
    @focus="
      () => {
        focussed = true
        emit('focus')
      }
    "
    @blur="
      () => {
        focussed = false
        emit('blur')
      }
    "
    @node="gatherNode($event)"
    @wheel="$event.target.blur()"
    @keydown.enter="emit('onkeyEnter')"
  >
    <template #suffixIcon v-if="password || search">
      <FormKitIcon
        v-if="password"
        :title="showPasswordTitle"
        class="w-6 mr-2 cursor-pointer"
        :icon="showPasswordIcon"
        @click.stop="toggleShowPassword"
      />

      <FormKitIcon
        v-if="search && isSearchSpinner"
        class="absolute right-1 w-6 cursor-pointer animate-spin"
        icon="spinner"
      />
    </template>
  </FormKit>
</template>
