<script setup lang="ts">
import { computed } from 'vue'
import useLocales from '@composable/useLocales'
import CGPill from '@component/CGPill.vue'

export interface Props {
  link: string
  handleRoute: (route: string) => void
}
withDefaults(defineProps<Props>(), {  })
const { LL } = useLocales()

const taskLink = computed(() => {
  // old links
  switch (__props.link) {
    case 'measure-upload-history':
      return 'measure-history-invoices'
    case 'improve-measures':
      return 'improve-goals'
    default:
      return __props.link
  }
})

const routesDict = {
  'tasks-board': '/tasks/board',
  'tasks-projects': '/tasks/projects',
  'configure-reporting-entities': '/configure/reporting-entities',
  'configure-datasets': '/configure/datasets',
  'configure-users': '/configure/users',
  'measure-activities': '/measure/activities',
  'measure-activity-history': '/measure/activity-history',
  'measure-history-invoices': '/measure/history/invoices',
  'performance-environmental': '/performance/environmental',
  'improve-goals': '/improve/goals',
  'report-materiality-overview': '/report/materiality/overview',
  'report-materiality-stakeholders': '/report/materiality/stakeholders',
  'report-materiality-impacts': '/report/materiality/impacts',
  'report-materiality-risks-opportunities': '/report/materiality/risks-opportunities',
  report: '/report',
  'supply-chain-suppliers': '/supply-chain/suppliers',
  'supply-chain-risks': '/supply-chain/risks',
}
const route = computed(() => routesDict[taskLink.value as keyof typeof routesDict])
const label = computed(() => LL.value.taskModal.linkDataTranslations[taskLink.value as I18NTaskLinkDataText]())
</script>
<template>
  <CGPill
    v-if="label"
    :text="label"
    class="bg-pages-background text-primary px-4 dark:bg-primary dark:text-white cursor-pointer text-ellipsis overflow-hidden ..."
    size="md"
    @click.stop="handleRoute(route)"
  />
</template>
