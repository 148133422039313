import { defineStore } from 'pinia'
import type { AxiosError } from 'axios'
import { getContextualHelp } from '@composable/useApi'

export interface ContextualHelpData {
  id: string
  reference: string
  html: HTMLElement
  ready: boolean
}

type contextualHelpStoredData<T> = StoredData<T> & {
  showHelp: boolean
}

export const useContextualHelpStore = defineStore('contextualHelp', {
  state: () =>
    ({
      data: [],
      current: null,
      loading: false,
      ready: false,
      statusCode: 0,
      statusMessage: '',
      showHelp: false,
    }) as contextualHelpStoredData<ContextualHelpData>,

  getters: {
    isOK(state) {
      return state.statusCode >= 200 && state.statusCode < 300
    },
  },
  actions: {
    resetState() {
      this.data = []
      this.current = null
      this.loading = false
      this.ready = false
      this.statusCode = 0
      this.statusMessage = ''
      this.showHelp = false
    },
    async fetchData(reference: string): Promise<boolean> {
      let referenceExists: boolean = false
      this.resetState()
      this.loading = true
      this.showHelp = true
      try {
        this.current = await getContextualHelp(reference)
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
        referenceExists = this.current ? true : false
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        referenceExists = false
      } finally {
        this.loading = false
      }
      return referenceExists
    },
  },
})
