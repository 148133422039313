<script setup lang="ts">
import { FormKitIcon } from '@formkit/vue'
import { computed } from 'vue'

export interface Props {
  text: string
  color?: string
  icon?: string
  size?: 'xs' | 'md' | 'lg'
  removeItem?: string
  onRemove?: (item: string) => void
}

withDefaults(defineProps<Props>(), { color: 'gray',size: 'xs', })
const colorClass = computed(() => `text-${__props.color} bg-${__props.color}/20`)
const iconClass = computed(() => {
  switch (__props.size) {
    case 'md':
      return 'w-4 h-4'
    case 'lg':
      return 'w-5 h-5'
    default:
      return 'w-3 h-3'
  }
})
</script>
<template>
  <div
    class="w-fit flex items-center gap-1 rounded-full px-3 py-1 h-fit text-center text-ellipsis"
    :class="[colorClass, `text-${size}`]"
  >
    <FormKitIcon v-if="icon" :icon="icon" class="flex items-center" :class="iconClass" />
    {{ text }}
    <FormKitIcon
      v-if="removeItem && onRemove !== undefined"
      icon="closeIcon"
      class="cursor-pointer ml-1"
      :class="iconClass"
      @click="onRemove(removeItem)"
    />
  </div>
</template>
