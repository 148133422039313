<script setup lang="ts">
import useLocales from '@composable/useLocales'

const { LL } = useLocales()
export interface Props {
  label: string
  subLabel?: string
  initials?: string
  big?: boolean
  external?: boolean
}

defineProps<Props>()
</script>

<template>
  <div class="w-10/12 flex items-center gap-3">
    <div
      class="flex-none flex justify-center items-center z-10 rounded-full font-bold"
      :class="
        big
          ? 'w-10 h-10 bg-gray-dark text-white text-sm'
          : 'w-6 h-6 bg-black dark:bg-white text-white dark:text-black text-xs pt-1'
      "
      :title="label ?? subLabel"
    >
      <slot name="icon">
        {{ external ? 'XX' : initials }}
      </slot>
    </div>
    <div class="flex flex-col overflow-x-hidden">
      <div v-if="big" class="w-full font-bold text-base" :title="external ? LL.report.assignment.external() : label">
        {{ external ? LL.report.assignment.external() : label }}
      </div>
      <div
        class="w-60 text-gray-light overflow-x-hidden text-ellipsis"
        :class="big ? 'text-sm' : 'text-base'"
        :title="subLabel"
      >
        {{ external && !big ? `[${LL.report.assignment.externalShort()}] ${subLabel}` : subLabel }}
      </div>
    </div>
  </div>
</template>
