import { apiFetch, apiCreate, apiDelete, apiUpdate } from '@api/client'
import { success, failure } from '@util/types'
import { isMatching, P } from 'ts-pattern'
import { AxiosError } from 'axios'

const projectPattern = {
  id: P.string,
  title: P.string,
  start_date: P.string,
  end_date: P.string,
  reporting_entity_id: P.string,
}
export type ProjectsDTO = P.infer<typeof projectPattern>

type SingleResult = Promise<Result<ProjectsDTO, AxiosError>>
type ResultList = Promise<Result<ProjectsDTO[], AxiosError>>

const isProjectDTO = isMatching(projectPattern)
const isProjectsDTO = isMatching(P.array(projectPattern))

export async function createProject(project: Omit<ProjectsDTO, 'id'>): SingleResult {
  const url = `/projects`
  try {
    const dto = await apiCreate(url, project)
    if (isProjectDTO(dto)) return success(dto)
    else return failure(new AxiosError('Unexpected API response'))
  } catch (err) {
    return failure(err as AxiosError)
  }
}

export async function fetchProjects(entityId: UUID, page = 1, pageSize = 100): ResultList {
  const url = `/projects`
  const params = { reporting_entity_id: entityId, page, pageSize }
  try {
    const dto = await apiFetch<Paginated<ProjectsDTO>>(url, params)
    if (isProjectsDTO(dto.results)) return success(dto.results)
    else return failure(new AxiosError('Unexpected API response'))
  } catch (err) {
    return failure(err as AxiosError)
  }
}

export async function updateProject(projectId: UUID, project: Partial<ProjectsDTO>): SingleResult {
  const url = `/projects/${projectId}`
  try {
    const dto = await apiUpdate(url, project)
    if (isProjectDTO(dto)) return success(dto)
    else return failure(new AxiosError('Unexpected API response'))
  } catch (err) {
    return failure(err as AxiosError)
  }
}

export async function deleteProject(projectId: UUID): Promise<Result<void, AxiosError>> {
  const url = `/projects/${projectId}`
  try {
    await apiDelete(url)
    return success(undefined)
  } catch (err) {
    return failure(err as AxiosError)
  }
}
