import { defineStore } from 'pinia'
import { handleS3UploadOfDisclosures } from '@composable/useApi'
import { fetchReportingRequirements } from '@api/report/requirements'
import { getUsers } from '@api/user'
import { requirements2questionnaires, convertRequirement, type FormattedUser } from '@/util/reporting'
import { useSnackStore } from '@store/snackStore'
import { type User, useUserStore } from '@store/user'
import type { AxiosError } from 'axios'
import { handleErrors } from '@/util/uploadErrors'
import type { ErrorPayload } from '../dataUploadInvoices'
import type { Attachment } from '@component/QuestionnaireSection/AttachmentSection.vue'
import type { ReportingRequirementTag } from '@adapter/reportingRequirementTag'

export type SimpleDataSpec = {
  data: {
    type: 'number' | 'integer' | 'boolean' | 'string' | 'none'
    widget?: 'textarea'
    title?: string
    title_de?: string
    choices?: {
      value: string
      value_de: string
    }[]
  }
}
export type SelectDataSpec = {
  data: {
    type: 'select'
    title: string
    title_de: string
    choices: {
      value: string
      value_de: string
    }[]
    multiple: boolean
  }
}
export type AttachmentPayload = {
  file_name: string
  reporting_requirement_id: number
  report_id: string
  user_id: string
  user_email: string
}
export type TableDataSpec = {
  data: {
    type: 'table'
    allow_more_rows: boolean
    allow_more_columns: boolean
    additional_headers: string[]
    columns: {
      type: SimpleDataSpec['data']['type']
      title: string
      title_de: string
      default: string[]
      default_de: string[]
      readonly: boolean
    }[]
  }
}

export type ReportingRequirement = {
  id: number
  reporting_standard_id: number
  section_id: number
  reference_code: string
  reference_slug: string
  title: string
  description: string
  order: number
  data_specification: SimpleDataSpec | TableDataSpec | SelectDataSpec
  is_live: boolean
  disclosure: null | {
    data: any
    assignment: any
    is_included_in_report: boolean
  }
  guidance_text: string | null
  help_text: string | null
  regulatory_text: string | null
  parent: number | null
  children: ReportingRequirement[]
  is_mandatory: boolean
  enable_attachments: boolean
  prompt_id: number | null
  tags: ReportingRequirementTag[]
}

type RequirementWithReportId = ReportingRequirement & { reportId: string }

type ReportingRequirementStoreData<T> = Omit<StoredData<T>, 'current'> & {
  showHelp: boolean
  selectedRequirement: T | null
  progressCount: number
  errors: ErrorPayload[]
  attachments: Attachment[]
  users: User[] | null
  current: Optional<QuestionnaireConfig>
  previousCurrent: Optional<QuestionnaireConfig>
  filters: {
    searchQuery?: Optional<string>
    showUnpublished: boolean
    status?: Optional<string>
    user?: Optional<FormattedUser>
    tags?: string[]
  }
  filteredData: RequirementWithReportId[]
  reportId: Optional<string>
}

export const useReportingRequirementStore = defineStore('ReportingRequirement', {
  state: () =>
    ({
      reportId: null,
      data: [],
      current: null,
      loading: true,
      ready: false,
      statusCode: 0,
      statusMessage: '',
      pagination: {
        currentPage: 1,
        pageCount: 1,
      },
      showHelp: false,
      selectedRequirement: null,
      progressCount: 0,
      errors: [],
      attachments: [],
      users: null,
      searchQuery: undefined,
      previousCurrent: null,
      filters: {
        searchQuery: null,
        status: null,
        user: null,
        showUnpublished: false,
      },
      filteredData: [],
    }) as ReportingRequirementStoreData<RequirementWithReportId>,
  getters: {
    reqData(): RequirementWithReportId[] {
      return this.filteredData.length ? this.filteredData : this.data
    },
  },
  actions: {
    resetState() {
      this.data = []
      this.current = null
      this.loading = true
      this.ready = false
      this.statusCode = 0
      this.statusMessage = ''
    },
    resetFilters() {
      this.filters = {
        searchQuery: null,
        status: null,
        user: null,
        showUnpublished: false,
        tags: undefined,
      }
    },
    async fetchData(reportId: string, standardId?: number) {
      this.resetState()
      const snackBar = useSnackStore()
      const params: { reportId: string; showUnpublished: boolean; searchQuery?: string; standardId?: number } = {
        reportId,
        showUnpublished: this.filters.showUnpublished,
        standardId,
      }

      try {
        const requirements = await fetchReportingRequirements(params)
        this.data = requirements.map<RequirementWithReportId>((req) => ({ ...req, reportId }))
        this.reportId = reportId
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
        this.loading = false
      } catch (err) {
        this.ready = false
        const response = (err as AxiosError).response
        this.statusCode = response?.status || 500
        this.statusMessage = response?.statusText || ''
        snackBar.error('issueFetchingRequirements')
      }
    },

    getQuestionnaires(
      reportId: string,
      sectionId: number,
      isAssigned: boolean | string,
      userId: string,
      showDisabled: boolean | string,
    ) {
      let reqs = [] as RequirementWithReportId[]
      if (!isAssigned) {
        reqs = this.data.filter((req) => req.section_id === sectionId)
      } else {
        reqs = this.data.filter(
          (req) =>
            req.section_id === sectionId &&
            (req.disclosure?.assignment?.assignee === userId ||
              (req.children.length > 0 &&
                req.children.some((tempReq) => tempReq.disclosure?.assignment?.assignee === userId))),
        )
      }
      if (!showDisabled) {
        reqs = reqs.filter((req) => req?.disclosure?.is_included_in_report || req.is_mandatory)
      }
      if (!reqs) return []
      return requirements2questionnaires(reportId, reqs)
    },

    getRequirement(requirementId: number, reportId: string, parentId: Optional<number> = null) {
      const reqId = parentId ?? requirementId
      const requirement = this.data.find((req) => req.id === reqId)
      if (requirement) {
        this.current = convertRequirement(requirement, reportId)
        return this.current
      }
      return null
    },

    updateDisclosureOnReportingRequirement(requirementId: number, updatedDisclosure: any, parentId?: number | null) {
      let requirement

      if (parentId === undefined || parentId === null) {
        requirement = this.data.find((req) => req.id === requirementId)
      } else {
        const parent = this.data.find((req) => req.id === parentId)
        if (!parent) {
          console.error('Parent requirement', parentId, 'does not exist!')
          return
        }
        requirement = parent.children.find((req) => req.id === requirementId)
      }

      if (!requirement) {
        console.error('Requirement does not exist!', requirementId)
        return
      }
      requirement.disclosure = updatedDisclosure
    },

    setHelpTextBar(id: number, parentId?: number | null) {
      try {
        const requirementId = parentId ?? id
        const selectedRequirement = this.data.find((cur) => cur.id === requirementId) as RequirementWithReportId
        if (selectedRequirement) {
          this.selectedRequirement = parentId
            ? (selectedRequirement.children.find((cur) => cur.id === id) as RequirementWithReportId)
            : selectedRequirement

          this.showHelp = true
        }
      } catch {
        console.error('ID not found')
        this.showHelp = false
      }
    },

    clearHelpBar() {
      this.showHelp = false
      this.selectedRequirement = null
    },

    async uploadData(fileList: FilePayload[], requirement_id: number, report_id: string) {
      const snackBar = useSnackStore()
      const userStore = useUserStore()
      const payload: AttachmentPayload = {
        file_name: '',
        reporting_requirement_id: requirement_id,
        report_id: report_id,
        user_id: userStore.user.id as string,
        user_email: userStore.user.email as string,
      }
      try {
        for (const file of fileList) {
          payload.file_name = file.name
          try {
            const attachment = await handleS3UploadOfDisclosures(payload, file)
            this.progressCount += 1
            this.attachments.push(attachment)
          } catch (error) {
            this.progressCount += 1
            const errorPayload = {
              name: file.name,
              status: handleErrors(error, true),
            }
            this.errors.push(errorPayload)
          }
        }
      } catch (error) {
        snackBar.error('createPdfUploadError')
      }
    },

    resetUploadProgess() {
      this.progressCount = 0
      this.errors = []
      this.attachments = []
    },

    async fetchUsers(entity: string, search: string) {
      const response = await getUsers({ reporting_entity: entity, search })
      this.users = response.results
    },
  },
})
