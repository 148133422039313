import { ref } from 'vue'
import { success, camelCased, snakeCased } from '@/util/types'
import { fetchProjects, createProject, updateProject, deleteProject, type ProjectsDTO } from '@api/tasks/projects'

export type Projects = CamelCase<ProjectsDTO>

const cache: Record<UUID, Projects> = {}

function getProjects(entityId: UUID): Projects[] {
  return Object.values(cache).filter((item) => item.reportingEntityId === entityId)
}

function getProjectById(id: UUID): Projects {
  return cache[id]
}

export default function projectsAdapter() {
  const loading = ref(false)
  const error = ref()

  const fetch = (entityId: UUID) => {
    loading.value = true
    return fetchProjects(entityId).then((result) => {
      if (!result.success) {
        error.value = result.error
        return
      }
      result.data.forEach((item) => (cache[item.id] = camelCased(item)))
      loading.value = false
    })
  }

  async function create(project: Omit<Projects, 'id'>) {
    const result = await createProject(snakeCased(project))
    if (!result.success) return result
    return success(camelCased(result.data))
  }

  async function update(projectId: UUID, project: Partial<Projects>) {
    const result = await updateProject(projectId, snakeCased(project))
    if (!result.success) return result
    return success(camelCased(result.data))
  }

  async function remove(projectId: UUID) {
    const result = await deleteProject(projectId)
    if (result.success) delete cache[projectId]
    return result
  }

  return {
    loading,
    error,
    fetch,
    create,
    update,
    remove,
    getProjects,
    getProjectById,
  }
}
