<script setup lang="ts">
import OverlayPanel from 'primevue/overlaypanel'
import { ref } from 'vue'

export interface Props {
  rootClass?: string
  contentClass?: string
}
withDefaults(defineProps<Props>(), { rootClass: 'rounded-md shadow-lg border bg-white dark:bg-black text-black dark:text-white border-gray-btn',contentClass: 'p-5', })
const op = ref()

function show(event: PointerEvent) {
  op.value.show(event)
}

function hide() {
  op.value.hide()
}

function toggle(event: PointerEvent) {
  op.value.toggle(event)
}

defineExpose({
  show,
  hide,
  toggle,
})

const pt = {
  root: {
    class: [`absolute left-0 top-0 mt-1 z-40 transform origin-center ${__props.rootClass}`],
  },
  content: {
    class: `items-center flex ${__props.contentClass}`,
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
}
</script>

<template>
  <OverlayPanel ref="op" :pt>
    <slot />
  </OverlayPanel>
</template>
