<script setup lang="ts">
import { computed } from 'vue'
import type { Editor } from '@tiptap/vue-3'
import ToolbarButtonGroup from './ToolbarButtonGroup.vue'
import ToolbarButton from './ToolbarButton.vue'

import useLocales from '@/composables/useLocales'

import RiBold from 'vue-remix-icons/icons/RiBold.vue'
import RiItalic from 'vue-remix-icons/icons/RiItalic.vue'
import RiH1 from 'vue-remix-icons/icons/RiH1.vue'
import RiH2 from 'vue-remix-icons/icons/RiH2.vue'
import RiFontColor from 'vue-remix-icons/icons/RiFontColor.vue'
import RiEmphasis from 'vue-remix-icons/icons/RiEmphasis.vue'
import RiListOrdered from 'vue-remix-icons/icons/RiListOrdered.vue'
import RiListUnordered from 'vue-remix-icons/icons/RiListUnordered.vue'
import RiFormatClear from 'vue-remix-icons/icons/RiFormatClear.vue'
import InsertData from '@component/QuestionnaireSection/InsertData.vue'
import { getParsedLastValue } from '@util/inputReference'

const props = defineProps<{
  editor: Editor
  /* eslint-disable @typescript-eslint/no-explicit-any */
  command: Record<string, (...args: any) => void>
  disabled?: boolean
}>()

const { LL } = useLocales()
const LLL = computed(() => LL.value.unit)
const supportedTextColors = ['#50a4f8', '#72c98e', '#e28344', '#a16ce0']

const supportedHighlightColors = ['#ff9900', '#00ff00']

function callRichTextUpdate(event: string) {
  // TODO: will enable in respective ticket
  props.command.insertContent(`<code reference-data="${event}">${getParsedLastValue(event, LLL.value)}</code>`)
}
</script>

<template>
  <menu
    class="relative flex justify-start items-center gap-2 border-b border-gray-light bg-background p-4 z-10 rounded-t-lg dark:bg-gray-dark"
    :class="{ 'cursor-not-allowed': disabled }"
  >
    <ToolbarButton
      :title="LL.richText.bold()"
      :active="editor.isActive('bold')"
      :disabled="!editor.can().toggleBold() || disabled"
      @click="command.toggleBold"
    >
      <RiBold />
    </ToolbarButton>
    <ToolbarButton
      :title="LL.richText.italic()"
      :active="editor.isActive('italic')"
      :disabled="!editor.can().toggleItalic() || disabled"
      @click="command.toggleItalic"
    >
      <RiItalic />
    </ToolbarButton>
    <ToolbarButton
      :title="LL.richText.h1()"
      :active="editor.isActive('heading', { level: 3 })"
      :disabled="!editor.can().toggleHeading({ level: 3 }) || disabled"
      @click="command.toggleHeading(3)"
    >
      <RiH1 />
    </ToolbarButton>
    <ToolbarButton
      :title="LL.richText.h2()"
      :active="editor.isActive('heading', { level: 4 })"
      :disabled="!editor.can().toggleHeading({ level: 4 }) || disabled"
      @click="command.toggleHeading(4)"
    >
      <RiH2 />
    </ToolbarButton>

    <ToolbarButtonGroup
      :title="LL.richText.textColor()"
      :active="editor.isActive('color')"
      :disabled="!editor.can().setColor('') || disabled"
    >
      <template #label>
        <RiFontColor />
      </template>
      <ToolbarButton @click="command.unsetColor()" :disabled="disabled">
        <div class="w-full h-full bg-black border border-black" />
      </ToolbarButton>
      <ToolbarButton
        v-for="color in supportedTextColors"
        :key="color"
        class="mx-1"
        @click="command.setColor(color)"
        :disabled="disabled"
      >
        <div class="w-full h-full border border-black" :style="{ backgroundColor: color }" />
      </ToolbarButton>
    </ToolbarButtonGroup>

    <ToolbarButtonGroup
      :title="LL.richText.bgColor()"
      :active="editor.isActive('highlight')"
      :disabled="!editor.can().setHighlight() || disabled"
    >
      <template #label>
        <RiEmphasis />
      </template>
      <ToolbarButton @click="command.unsetHighlight()" :disabled="disabled">
        <div class="w-full h-full bg-white border border-black" />
      </ToolbarButton>
      <ToolbarButton
        v-for="color in supportedHighlightColors"
        :key="color"
        @click="command.setHighlight(color)"
        :disabled="disabled"
      >
        <div class="w-full h-full border border-black" :style="{ backgroundColor: color }" />
      </ToolbarButton>
    </ToolbarButtonGroup>

    <ToolbarButton
      :title="LL.richText.bulletedList()"
      :active="editor.isActive('bulletList')"
      :disabled="!editor.can().toggleBulletList() || disabled"
      @click="command.toggleBulletList()"
    >
      <RiListUnordered />
    </ToolbarButton>
    <ToolbarButton
      :title="LL.richText.orderedList()"
      :active="editor.isActive('orderedList')"
      :disabled="!editor.can().toggleOrderedList() || disabled"
      @click="command.toggleOrderedList()"
    >
      <RiListOrdered />
    </ToolbarButton>

    <ToolbarButton :title="LL.richText.removeStyling()" @click="command.removeStyles()" :disabled="disabled">
      <RiFormatClear />
    </ToolbarButton>

    <ToolbarButton class="absolute right-4" :disabled="disabled">
      <InsertData :type="'rich-text'" :disabled inline @on-submit="callRichTextUpdate($event as string)" />
    </ToolbarButton>
  </menu>
</template>
