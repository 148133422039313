import { computed, type Ref, type ComputedRef } from 'vue'
import type { Translations } from '../i18n/i18n-types'
import useLocales from './useLocales'

export default function useLoaderMessages(finished: Ref<boolean> | ComputedRef<boolean>) {
  const { LL } = useLocales()

  let loadingMessageIndex = $ref(0)
  const loadingMessage = computed(() => {
    const index = `${loadingMessageIndex}` as keyof Translations['appLoader']['keywords']
    const keyword = LL.value.appLoader.keywords[index]()
    return LL.value.appLoader.title(keyword)
  })

  // sets a loading new message with ever longer waiting times
  function nextLoaderMessage() {
    loadingMessageIndex++
    if (!finished.value) setTimeout(nextLoaderMessage, 1000 + loadingMessageIndex * 1000)
    else loadingMessageIndex = 0
  }

  return {
    startLoading: () => setTimeout(nextLoaderMessage, 1000),
    loadingMessage,
  }
}
