import { ref, type Ref } from 'vue'
import { Editor, EditorContent, type Extensions } from '@tiptap/vue-3'
import { Document } from '@tiptap/extension-document'
import { Text } from '@tiptap/extension-text'
import { Paragraph } from '@tiptap/extension-paragraph'
import { TextStyle } from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import { ListItem } from '@tiptap/extension-list-item'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { BulletList } from '@tiptap/extension-bullet-list'
import { HardBreak } from '@tiptap/extension-hard-break'
import { Heading, type Level } from '@tiptap/extension-heading'
import { Bold } from '@tiptap/extension-bold'
import { Italic } from '@tiptap/extension-italic'
import { Highlight } from '@tiptap/extension-highlight'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Typography } from '@tiptap/extension-typography'
import { Code as ImportCode } from '@tiptap/extension-code'

/* Potential other useful extensions to evaluate:
 * https://tiptap.dev/api/extensions/character-count
 * https://tiptap.dev/api/extensions/history
 */

export interface RichTextConfig {
  content?: string // prefill editor with this content
  placeholder?: string // if empty, show this placeholder as a hint for the user
  headingPlaceholder?: string // placeholder shown, when a user adds a heading
  headingLevels?: Level[] // allowed headings, defaults to [1, 2]
  contentRef?: Ref // if given, it will be automatically kept up to date with the editor content
  onUpdate?: (newValue: string) => void // function to be called on every content update
}

// simple markdown parser
export function simpleMarkdown(text: string) {
  // Replacinf double quotes initially with two lines
  text = text.replace('\n\n', '<br/><br/>')
  const modifiedHtml = text.split('\n').map(
    (p) => {
      return `${p
        .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') // replace ** ${text} ** with bold which happens in some headings
        .replace(/\t(.*?)/g, '<span style="margin-left: 5px">$1</span>') // replace \t with space
        .replace(/\*(.*?)/g, '-')}<br/>`
    }, // replace * sometimes present as bullet point with -
    // a traditional .replace(/^[\*|+|-][ |.](.*)/gm, '<ul><li>$1</li></ul>' ) did not work due to mismatch in
    // the LLM response format
  )
  return modifiedHtml.join('\n').trim()
}

export default function useRichText({
  content = '',
  placeholder = '',
  headingPlaceholder = '',
  headingLevels = [3, 4],
  contentRef,
  onUpdate,
}: RichTextConfig) {
  Heading.configure({ levels: headingLevels })

  Placeholder.configure({
    placeholder: ({ node }: any) => {
      if (node.type.name === 'heading') {
        return headingPlaceholder
      }
      return placeholder
    },
  })

  // adding attribute so it doesn't get removed
  const ImportItem = ImportCode.extend({
    addAttributes() {
      return {
        'reference-data': {
          default: null,
          parseHTML: (element: HTMLElement) => {
            return element.hasAttribute('reference-data') ? element.getAttribute('reference-data') : null
          },
        },
      }
    },
  })

  const extensions: Extensions = [
    Document,
    Text,
    Paragraph,
    TextStyle,
    Color,
    ListItem,
    OrderedList,
    BulletList,
    HardBreak,
    Heading,
    Bold,
    Italic,
    Highlight.configure({ multicolor: true }),
    Placeholder,
    Typography,
    ImportItem.configure({
      HTMLAttributes: {
        class: 'bg-gray-btn p-1 rounded-md cursor-pointer before:content-none after:content-none pointer-events-none',
        title: 'Imported ref',
      },
    }),
  ]

  const focussed = ref(false)
  let blurTimeout: NodeJS.Timeout

  const editor = new Editor({
    content,
    extensions,
    editorProps: {
      attributes: {
        class: 'prose prose-md focus:outline-none w-full max-w-full my-2 dark:text-white',
      },
    },
    onUpdate: ({ editor }: any) => {
      if (!contentRef && !onUpdate) return
      const newValue = editor.getHTML()
      if (contentRef) contentRef.value = newValue
      if (onUpdate) {
        onUpdate(newValue)
      }
    },
    onFocus: ({ editor }: any) => {
      if (editor.isEditable) {
        clearTimeout(blurTimeout)
        focussed.value = true
      }
    },
    onBlur: () => {
      // delayed blur to avoid hiding the toolbar when clicking on it
      blurTimeout = setTimeout(() => {
        focussed.value = false
      }, 200)
    },
  })

  const command = {
    toggleBold: () => editor.chain().focus().toggleBold().run(),
    toggleItalic: () => editor.chain().focus().toggleItalic().run(),
    toggleHeading: (level: Level) => editor.chain().focus().toggleHeading({ level }).run(),
    setColor: (color: string) => editor.chain().focus().setColor(color).run(),
    setHighlight: (color: string) => editor.chain().focus().toggleHighlight({ color }).run(),
    unsetColor: () => editor.chain().focus().unsetColor().run(),
    unsetHighlight: () => editor.chain().focus().unsetHighlight().run(),
    toggleOrderedList: () => editor.chain().focus().toggleOrderedList().run(),
    toggleBulletList: () => editor.chain().focus().toggleBulletList().run(),
    removeStyles: () => editor.chain().focus().unsetAllMarks().run(),
    insertContent: (content: string) => editor.chain().focus().insertContent(content).run(),
  }

  return {
    contentRef,
    focussed,
    editor,
    EditorContent,
    command,
  }
}
