import { type Ref, inject, computed } from 'vue'

/**
 * Composable function to help components that are rendered as dialog.
 * It provides reactive properties and functions related to dialog state and actions,
 * such as checking if the current component is inside a dialog, closing the dialog,
 * and accessing the dialog's data.
 *
 * @template O The type of the value that can be passed when closing the dialog.
 * @template I The type of the data associated with the dialog.
 * @returns {Object} An object containing:
 * - `isInsideDialog`: A computed property that indicates if the current component is inside a dialog.
 * - `dialogRef`: The dialog reference object, or `undefined` if not inside a dialog.
 * - `closeDialog`: A function to close the dialog, optionally passing a value of type `O`, which can then be handled by the dialog onClose handler.
 * - `data`: A computed property that returns the dialog's data of type `I` or `undefined`, passed via `dialog.open`.
 *
 * @example
 * ```vue
 * <script setup lang="ts">
 * import { useAsDialog } from '@/composables/useAsDialog'
 *
 * // component expects data of type { message: string } and returns a number
 * // when dialog is closed:
 * const { isInsideDialog, closeDialog, data } = useAsDialog<number, { message: string }>()
 *
 * if (isInsideDialog.value) {
 *   console.log('I am inside a dialog!')
 *   console.log(data,value?.message) // access dialog data
 * }
 *
 * // Closing the dialog and optionally passing a value
 * closeDialog(42);
 * </script>
 * ```
 */
export default function useAsDialog<O = any, I = any>() {
  const dialogRef: Ref<{ close: (value?: O) => void; data: I }> | undefined = inject('dialogRef')

  const isInsideDialog = computed(() => dialogRef !== undefined)

  const closeDialog = (value?: O) => {
    if (dialogRef === undefined) return
    dialogRef.value.close(value)
  }

  const data = computed<I | undefined>(() => dialogRef?.value.data)

  return { isInsideDialog, dialogRef, closeDialog, data }
}
