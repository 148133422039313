import {
  authlessAxios,
  login as loginFunc,
  type LoginCredentials,
  apiFetch,
  apiCreate,
  apiUpdate,
  apiDelete,
} from './client'
import type { User } from '@store/user'
import { AxiosError } from 'axios'

interface AuthErrorResponse {
  non_field_errors: string
  detail: string
  new_password?: string[]
}

export { logout, refreshAccess, tokenStore } from './client'

export async function passwordResetRequest(email: string, resetLink: URL) {
  return authlessAxios.post('/auth/users/reset_password/', {
    email,
    password_reset_link: resetLink.href,
  })
}

export async function passwordReset(uid: string, token: string, newPassword: string) {
  return authlessAxios.post('/auth/users/reset_password_confirm/', {
    uid,
    token,
    new_password: newPassword,
  })
}

export async function getProfile(): Promise<User> {
  const url = import.meta.env.VITE_USER_URL
  return apiFetch<User>(url)
}

export async function login(credentials: LoginCredentials): Promise<User> {
  await loginFunc(credentials)
  return getProfile()
}

export async function signup(formData: {
  uid: string
  token: string
  newPassword: string
  firstName: string
  lastName: string
  position: string
}): Promise<User> {
  const { uid, token, newPassword, firstName, lastName, position } = formData

  try {
    const response = await authlessAxios.post('/auth/users/activation/', {
      uid,
      token,
      first_name: firstName,
      last_name: lastName,
      position,
      new_password: newPassword,
    })
    return await login({ email: response.data.email, password: newPassword })
  } catch (err) {
    let message = 'generic'
    if (err instanceof AxiosError) {
      const data: AuthErrorResponse = err.response?.data

      if (data.non_field_errors) message = 'alreadyActivated'
      else if (data.detail === 'Not a valid token.') message = 'invalid'
      else if (data.detail === 'Not found.') message = 'notFound'
      else if (data.new_password && data.new_password.length) {
        message = data.new_password[0]
      }
    }

    return Promise.reject(message)
  }
}

export async function getUserProfiles(page: number): Promise<Paginated<User>> {
  const url = 'auth/users/'
  const params: Record<string, any> = {
    //TODO: add entries per page parameter when supported
    page: page,
  }
  const response = await apiFetch<Paginated<User>>(url, params)
  return response
}

export async function getUserProfile(id: string): Promise<User> {
  const url = `auth/users/${id}/`
  return await apiFetch<User>(url)
}

export async function inviteUser(user: Partial<User>): Promise<Partial<User>> {
  const url = `auth/users/`
  const response = await apiCreate<Partial<User>>(url, user)
  return response
}

export async function updateUserDetails(user: Partial<User>, id: string): Promise<User> {
  const url = `auth/users/${id}/`
  return await apiUpdate<User>(url, user)
}

export async function deleteUser(id: string): Promise<void> {
  const url = `auth/users/${id}`
  await apiDelete<User>(url)
}

export async function resendUserActivation(email: string): Promise<void> {
  const url = `auth/users/resend_activation/`
  await apiCreate<{ email: string }>(url, { email: email })
}

export async function updateUser(userId: string, payload: userSettingPayload): Promise<User> {
  const url = `/auth/users/${userId}/`
  return await apiUpdate<User>(url, payload)
}

export async function getUsers(filters: { reporting_entity: string; search: string }): Promise<Paginated<User>> {
  const url = 'auth/users/'
  return await apiFetch<Paginated<User>>(url, filters)
}

interface SSOInfo {
  id: string
  web_domains: string[]
  cognito_user_pool_id: string
  identity_provider: string
  client_id: string
  reporting_entity: string
}

export async function sso(email: string): Promise<Result<SSOInfo, null>> {
  const url = 'sso-connections'
  const domain = email.split('@')[1]
  const response = await apiFetch<Paginated<SSOInfo>>(url, { web_domain: domain })
  if (!response.results.length)
    return {
      success: false,
      error: null,
    }
  return {
    success: true,
    data: response.results[0],
  }
}
