<script setup lang="ts">
import { FormKitIcon } from '@formkit/vue'
import useLocales from '@composable/useLocales'

const { LL } = useLocales()
</script>

<template>
  <div class="grid place-content-center justify-items-center h-full">
    <h2 class="text-xl font-medium mb-4">{{ LL.onboarding.slide1.welcome() }}</h2>
    <FormKitIcon class="w-96 mb-8" icon="logo" />
    <p class="w-[535px] text-center">
      {{ LL.onboarding.slide1.text() }}
    </p>
  </div>
</template>
