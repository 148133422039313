import { defineStore } from 'pinia'

export interface SnackBarItem {
  heading?: I18NSnackHeadingKey
  description: I18NSnackMessageKey | string
  id: string
  showCloseIcon?: boolean
  theme?: string
  lifetime?: number
  sideIcon?: string
}

const DEFAULT_LIFETIME = 5000
const DEFAULT_LIFETIME_SUCCESS = 2000
const DEFAULT_LIFETIME_WARNING = DEFAULT_LIFETIME
const DEFAULT_LIFETIME_ERROR = Infinity
const DEFAULT_LIFETIME_INFO = DEFAULT_LIFETIME

export const useSnackStore = defineStore('snackData', () => {
  const snackData: { [snackId: string]: SnackBarItem } = $ref({})

  function addSnackBar(snack: SnackBarItem) {
    snackData[snack.id] = snack
    /*
      If the snackbar lifetime is intended to immortal a default close option is set.
      So that user can remove it thenselves later.
     */
    if (snack.lifetime !== undefined && (snack.lifetime <= 0 || snack.lifetime === Infinity)) {
      snackData[snack.id].showCloseIcon = true
      return
    }
    setTimeout(() => deleteSnackBar(snack.id), snack.lifetime || DEFAULT_LIFETIME)
  }

  function deleteSnackBar(id: string) {
    delete snackData[id]
  }

  function success(
    message: I18NSnackMessageKey | string,
    lifetime = DEFAULT_LIFETIME_SUCCESS,
    heading?: I18NSnackHeadingKey,
  ) {
    addSnackBar({
      description: message,
      theme: 'success',
      id: `successMessage${snackData.length}`,
      heading: heading,
      lifetime: lifetime,
    })
  }

  function error(
    message: I18NSnackMessageKey | string,
    lifetime = DEFAULT_LIFETIME_ERROR,
    heading?: I18NSnackHeadingKey,
    icon = 'errorIcon',
  ) {
    addSnackBar({
      description: message,
      theme: 'error',
      id: `errorMessage${snackData.length}`,
      heading: heading,
      sideIcon: icon,
      lifetime: lifetime,
    })
  }

  function warning(
    message: I18NSnackMessageKey | string,
    lifetime = DEFAULT_LIFETIME_WARNING,
    heading?: I18NSnackHeadingKey,
    icon = 'infoIcon',
  ) {
    addSnackBar({
      description: message,
      theme: 'warning',
      id: `warningMessage${snackData.length}`,
      heading: heading,
      sideIcon: icon,
      lifetime: lifetime,
    })
  }

  function info(
    message: I18NSnackMessageKey | string,
    lifetime = DEFAULT_LIFETIME_INFO,
    heading?: I18NSnackHeadingKey,
    icon = 'infoIcon',
  ) {
    addSnackBar({
      description: message,
      theme: 'primary',
      id: `infoMessage${snackData.length}`,
      heading: heading,
      sideIcon: icon,
      lifetime: lifetime,
    })
  }

  return $$({
    snackData,
    addSnackBar,
    deleteSnackBar,
    success,
    error,
    warning,
    info,
  })
})
