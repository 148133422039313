<script setup lang="ts">
import CascadeSelect from 'primevue/cascadeselect'
import { FormKitIcon } from '@formkit/vue'
import useLocales from '@composable/useLocales'
const { LL } = useLocales()
import type { TreeNode } from 'primevue/treenode'

export interface Props {
  items: TreeNode[]
  disabled?: boolean
  value?: string
  limitHeight?: boolean
  removeBG?: boolean
}

export interface Events {
  (e: 'select', value: string): void
}

withDefaults(defineProps<Props>(), {  })
const emit = defineEmits<Events>()

function handleOptionSelect(value: string) {
  emit('select', value)
}
</script>

<template>
  <CascadeSelect
    :options="items"
    optionLabel="label"
    optionGroupLabel="label"
    optionValue="value"
    :disabled="disabled"
    :optionGroupChildren="['children']"
    @update:modelValue="handleOptionSelect"
    :pt="{
      root: ({ props }) => ({
        class: [
          'relative flex gap-2 items-center w-fit py-1 px-2 rounded-full text-blue-800 transition-all duration-200 cursor-pointer select-none',
          {
            'opacity-60 pointer-events-none cursor-default': props.disabled,
            'bg-pages-background dark:bg-gray-btn': !removeBG,
          },
        ],
      }),
      dropdownbutton: 'flex items-center text-black',
      panel: {
        class: [
          'w-64 bg-black text-white dark:border dark:border-white-soft rounded-lg py-2',
          { 'max-h-[450px] overflow-scroll': limitHeight },
        ],
      },
      item: 'relative border-b border-gray-dark hover:bg-gray',
      content: 'relative flex items-center px-3 no-underline overflow-hidden cursor-pointer select-none',
      sublist: 'static sm:absolute list-none w-full py-2.5 m-0  bg-black border-0 rounded-lg  z-50',
    }"
  >
    <template #option="{ option }">
      <div
        class="flex flex-row items-center py-2.5 w-full relative text-white no-underline overflow-hidden cursor-pointer select-none"
      >
        <FormKitIcon :icon="option.icon" class="w-6 h-6 mr-2" />
        <div class="w-full overflow-ellipsis line-clamp-1" :title="option.label">{{ option.label }}</div>
      </div>
    </template>
    <template #value>
      {{ LL.taskModal.linkDataTranslations[value as I18NTaskLinkDataText]() }}
    </template>
    <template #dropdownicon>
      <slot name="dropdownicon">
        <FormKitIcon class="pl-2 cursor-pointer dark:text-white" icon="fileEditIcon" />
      </slot>
    </template>
  </CascadeSelect>
</template>
