import { apiCreate } from '@api/client'

export interface GhgpMetricRequestBody {
  reference_reporting_entity_id: string
  dataset_ids: string[]
  start_date: string
  end_date: string
  metric?: string
}

export interface StaticMetricResponse {
  metric: string
  label: string
  quantity: GRI.Quantity
}

export async function createGhgpMetricRequest(
  payload: GhgpMetricRequestBody,
): Promise<StaticMetricResponse[] | StaticMetricResponse> {
  return apiCreate<StaticMetricResponse[] | StaticMetricResponse>('/gri/ghgp-metrics', payload, 3)
}
