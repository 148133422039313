import type { RouteLocationNormalized, Router } from 'vue-router'

export const ROUTE_ITEMS = {
  dashboard: { name: 'performance-environmental', path: '/performance/environmental' },
  accounting: { name: 'measure-activities', path: '/measure/activities' },
  improvement: { name: 'improve-goals', path: '/improve/goals' },
  reporting: { name: 'report', path: '/report' },
  configuration: { name: 'configure-:section', path: '/configure/datasets' },
  tasks: { name: 'tasks', path: '/tasks/' },
  materiality: { name: 'report-materiality', path: '/report/materiality' },
  survey: { name: 'survey', path: '/survey/' },
  supplyChain: { name: 'supply-chain', path: '/supply-chain' },
  euTaxonomy: { name: 'eu-taxonomy', path: '/eu-taxonomy' },
}

const NON_AUTH_ROUTES = ['/login', '/signup', '/reset-password']
const EXTERNAL_ROUTES = ['/survey']
const EMPTY_DATASET_ROUTES = ['/performance', '/improve', '/configure']

export const HOME_ROUTE = { name: 'home', path: '/home' }

export function absoluteRouteURL(router: Router, routePath: string): URL {
  const resolvedRoute = router.resolve(routePath)
  return new URL(resolvedRoute.href, window.location.origin)
}

export function parentRouteMatches(router: Router, route: RouteLocationNormalized) {
  const current = router.currentRoute.value
  const currentParent = current.matched.at(0)
  const routeParent = route.matched.at(0)

  return !!routeParent && routeParent?.path === currentParent?.path
}

export function isNonAuthRoute(path: string) {
  return NON_AUTH_ROUTES.some((route) => path.startsWith(route))
}
export function isExternalRoute(path: string) {
  return EXTERNAL_ROUTES.some((route) => path.startsWith(route))
}
export function isEmptyDatasetRoute(path: string) {
  return EMPTY_DATASET_ROUTES.some((route) => path.startsWith(route))
}

// TODO: this method is only needed until thebackend replaces permission stings to new ones
export function replaceRouteName(path: string) {
  if (path.startsWith('performance')) {
    return 'dashboard'
  } else if (path.startsWith('measure')) {
    return 'accounting'
  } else if (path.startsWith('improve')) {
    return 'improvement'
  } else if (path.startsWith('report') || path.startsWith('reports')) {
    return 'reporting'
  } else if (path.startsWith('configure')) {
    return 'configuration'
  } else if (path.startsWith('tasks')) {
    return 'tasks'
  } else if (path.startsWith('survey')) {
    return 'survey'
  } else if (path.startsWith('supply-chain')) {
    return 'supply-chain'
  } else if (path.startsWith('eu-taxonomy')) {
    return 'eu-taxonomy'
  }
  // else if (path.startsWith('report/materiality')) {
  //   return 'materiality'
  // }
  else {
    return path
  }
}
