import { defineStore } from 'pinia'
import {
  fetchBenchmarkOptions,
  fetchGriData,
  getBenchMarkOfDataset,
  updateBenchmarkSector,
  getActivitySpecificFileDetails,
} from '@composable/useApi'
import type { GRIData, GRIScope, GRIParams } from '@/util/gri'
import type { AxiosError } from 'axios'
import { useSnackStore } from '@store/snackStore'

export interface FileSpecificData {
  created_at: string
  dataset_id: string
  file: string
  id: string
  import_layout: string
  is_processed: string
  original_name: string
  url: string
  user_email: string
  user_id: string
}

export interface FileSpecificPdfData {
  created_at: string
  dataset_id: string
  file: string
  file_name: string | null
  file_post_info: {
    url: string
    fields: any
  }
  hash: string
  hierarchy_id: string
  id: string
  modified_at: string
  next: string | null
  original_upload: any
  original_upload_id: string
  page_no: number
  previous: null | number
  upload_status: 'ACCEPTED' | 'REJECTED' | 'FAILED' | 'SUPERVISED'
  user_email: string
  user_id: string
}
export interface BenchmarkSectors {
  display_name: string
  value: number | null
}

export interface BenchmarkItem {
  created_at: string
  dataset_id: string
  hierarchy_id: string
  modified_at: string
  sector: number | null
}

export interface BenchMarkOptions {
  actions: {
    POST: {
      sector: {
        choices: BenchmarkSectors[]
      }
    }
  }
  description: string
  name: string
  parses: string[]
  renders: []
}

export interface MappedGRIData {
  scope: GRIScope
  data: GRIData
  startDate: string
  endDate: string
}

type GRIStoreData<T> = StoredData<T> & {
  benchSectors: BenchmarkSectors[]
  benchMarkSelected: BenchmarkItem
  fileData: FileSpecificData | FileSpecificPdfData
}

export const useGRIDataStore = defineStore('gri-data', {
  state: () =>
    ({
      data: [],
      current: null,
      loading: true,
      ready: false,
      statusCode: 0,
      statusMessage: '',
      benchSectors: [] as BenchmarkSectors[],
      benchMarkSelected: {} as BenchmarkItem,
      fileData: {} as FileSpecificData | FileSpecificPdfData,
    }) as GRIStoreData<MappedGRIData>,

  getters: {
    isOK(state) {
      return state.statusCode >= 200 && state.statusCode < 300
    },
    getFileData(state) {
      return state.fileData
    },
  },
  actions: {
    resetState() {
      this.data = []
      this.current = null
      this.ready = false
      this.statusCode = 0
      this.statusMessage = ''
    },
    async fetchData(
      scope: GRIScope,
      params: GRIParams,
      dashboard?: boolean, // make dashboard data load faster
    ) {
      this.resetState()

      try {
        this.loading = true
        const data = await fetchGriData(scope, params, dashboard)

        const { startDate, endDate } = params
        this.data.push({ scope, data, startDate, endDate })

        // this.current is not used
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
      } catch (error) {
        this.statusCode = (error as AxiosError)?.response?.status || 500
        this.statusMessage = (error as AxiosError)?.response?.statusText || ''
      } finally {
        this.loading = false
      }

      try {
        if (!this.benchMarkSelected.dataset_id && params.version === '2') {
          this.benchMarkSelected = await getBenchMarkOfDataset(params.datasetId)
        }
      } catch (error: unknown) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
      }
    },
    async fetchSectorOptions() {
      const sectors = await fetchBenchmarkOptions()
      this.benchSectors = [{ display_name: 'None', value: null }, ...sectors.actions.POST.sector.choices]
    },
    async updateSector(datasetId: string, sectorId: number | null) {
      const snackBar = useSnackStore()
      try {
        this.loading = true
        this.benchMarkSelected = await updateBenchmarkSector(datasetId, sectorId)
        snackBar.success('sectorUpdated')
      } catch (error) {
        snackBar.error('sectorUpdateError')
      } finally {
        this.loading = false
      }
    },
    async getFileUrlData(type: string, fileId: string) {
      const snackBar = useSnackStore()
      try {
        this.fileData = await getActivitySpecificFileDetails(type, fileId)
        if (this.fileData.file === undefined) {
          snackBar.error('errorFectingInvoceData')
        }
      } catch (error) {
        this.fileData = {} as FileSpecificData | FileSpecificPdfData
        snackBar.error('errorFectingInvoceData')
      }
    },
    byScope(scope: GRIScope) {
      return this.data.find((entry) => entry.scope === scope)
    },
    hasScope(scope: GRIScope) {
      return this.data.some((entry) => entry.scope === scope)
    },
  },
})
