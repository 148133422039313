import { defaultConfig } from '@formkit/vue'
import { de } from '@formkit/i18n'
import {
  search,
  reorder,
  settings,
  people,
  share,
  tools,
  list,
  group,
  file,
  caretDown,
  spinner,
  eye,
  download,
  fileDoc,
} from '@formkit/icons'
import email from '@/assets/icons/sms.svg?raw'
import password from '@/assets/icons/lock.svg?raw'
import homeIcon from '@/assets/icons/Home.svg?raw'
import dashboardIcon from '@/assets/icons/speedometerIcon.svg?raw'
import strategyIcon from 'remixicon/icons/Map/map-line.svg?raw'
import improvementIcon from '@/assets/icons/leafIcon.svg?raw'
import reportingIcon from '@/assets/icons/clipboard-text.svg?raw'
import copyIcon from '@/assets/icons/copy.svg?raw'
import fileSearchIcon from '@/assets/icons/fileSearchIcon.svg?raw'
import fileEditIcon from '@/assets/icons/fileEditIcon.svg?raw'
import configurationIcon from '@/assets/icons/configurationIcon.svg?raw'
import helpIcon from '@/assets/icons/Help.svg?raw'
import generalDataIcon from '@/assets/icons/DefaultLogoCompany.svg?raw'
import hamburgerIcon from '@/assets/icons/HamburgerMenu.svg?raw'
import logOutIcon from '@/assets/icons/Logout.svg?raw'
import editIcon from '@/assets/icons/Edit.svg?raw'
import inboxIcon from '@/assets/icons/Inbox.svg?raw'
import entityIcon from 'remixicon/icons/Editor/organization-chart.svg?raw'
import datasetIcon from 'remixicon/icons/Document/folder-line.svg?raw'
import upDownArrow from 'remixicon/icons/Development/code-line.svg?raw'
import questionnaireIcon from '@/assets/icons/message-question.svg?raw'
import reActivateIcon from 'remixicon/icons/User/user-add-line.svg?raw'
import sunIcon from 'remixicon/icons/Weather/sun-line.svg?raw'
import moonIcon from 'remixicon/icons/Weather/moon-line.svg?raw'
import errorIcon from 'remixicon/icons/System/error-warning-line.svg?raw'
import thumbUp from 'remixicon/icons/System/thumb-up-line.svg?raw'
import infoIcon from '@/assets/icons/infoIcon.svg?raw'
import warningIcon from 'remixicon/icons/System/forbid-2-line.svg?raw'
import checkIcon from '@/assets/icons/check.svg?raw'
import circleArrowRightIcon from '@/assets/icons/arrow-circle-right.svg?raw'
import circleArrowRightInvertedIcon from '@/assets/icons/arrow-circle-right-invert.svg?raw'
import calendar from '@/assets/icons/calendar.svg?raw'
import addIcon from '@/assets/icons/AddIcon.svg?raw'
import deleteIcon from '@/assets/icons/DeleteIcon.svg?raw'
import reportDownloadIcon from '@/assets/icons/ReportDownloadIcon.svg?raw'
import close from '@/assets/icons/close-circle.svg?raw'
import dialogClose from '@/assets/icons/cross.svg?raw'
import notificationIcon from '@/assets/icons/notificationIcon.svg?raw'
import maximize from '@/assets/icons/maximize.svg?raw'
import caretLeftIcon from '@/assets/icons/caret-left.svg?raw'
import info from '@/assets/icons/info.svg?raw'
import fileIcon from '@/assets/icons/file.svg?raw'
import document from '@/assets/icons/document.svg?raw'
import documentUpload from '@/assets/icons/documentUpload.svg?raw'
import documentDownload from '@/assets/icons/documentDownload.svg?raw'
import tickCircle from '@/assets/icons/tickCircle.svg?raw'
import closeCircle from '@/assets/icons/closeCircle.svg?raw'
import closeIcon from '@/assets/icons/closeIcon.svg?raw'
import linkExternal from '@/assets/icons/externalLink.svg?raw'
import link from '@/assets/icons/link.svg?raw'
import filter from '@/assets/icons/filter.svg?raw'
import arrowUp from '@/assets/icons/arrow-up.svg?raw'
import arrowDown from '@/assets/icons/arrow-down.svg?raw'
import minusSquareIcon from '@/assets/icons/minus-square.svg?raw'
import accountingIcon from '@/assets/icons/accountingIcon.svg?raw'
import exportIcon from '@/assets/icons/exportIcon.svg?raw'
import linearArrowLeft from '@/assets/icons/linear-arrow-left.svg?raw'
import energyIcon from '@/assets/icons/energyIcon.svg?raw'
import waterIcon from '@/assets/icons/waterIcon.svg?raw'
import gasStationIcon from '@/assets/icons/gasStationIcon.svg?raw'
import refrigerantIcon from '@/assets/icons/glass.svg?raw'
import airplaneIcon from '@/assets/icons/airplaneIcon.svg?raw'
import accommodationIcon from '@/assets/icons/accommodationIcon.svg?raw'
import busIcon from '@/assets/icons/busIcon.svg?raw'
import carIcon from '@/assets/icons/carIcon.svg?raw'
import homeOfficeIcon from '@/assets/icons/homeOfficeIcon.svg?raw'
import shopGoodsIcon from '@/assets/icons/shop-goodsIcon.svg?raw'
import creditCardIcon from '@/assets/icons/creditCardIcon.svg?raw'
import offsetIcon from '@/assets/icons/offsetIcon.svg?raw'
import globe from '@/assets/icons/globe.svg?raw'
import profile from '@/assets/icons/profile.svg?raw'
import profile2 from '@/assets/icons/profile-2.svg?raw'
import infoQuestionIcon from '@/assets/icons/infoCircleQuestionIcon.svg?raw'
import moreIcon from '@/assets/icons/moreIcon.svg?raw'
import buildingIcon from '@/assets/icons/buildingIcon.svg?raw'
import folderIcon from '@/assets/icons/folderIcon.svg?raw'
import smsTracking from '@/assets/icons/sms-tracking.svg?raw'
import logo from '@/assets/logo_full.svg?raw'
import circleArrowRightOutline from '@/assets/icons/arrow-circle-right-outline.svg?raw'
import circleArrowLeftOutline from '@/assets/icons/arrow-circle-left-outline.svg?raw'
import tickSquareIcon from '@/assets/icons/tick-square.svg?raw'
import entityTenant from '@/assets/icons/entity-tenant.svg?raw'
import entityOrg from '@/assets/icons/entity-org.svg?raw'
import entityOperation from '@/assets/icons/entity-operation.svg?raw'
import entityProject from '@/assets/icons/entity-project.svg?raw'
import entityAbstract from '@/assets/icons/entity-abstract.svg?raw'
import dataTreeIcon from '@/assets/icons/dataTree.svg?raw'
import addSquareIcon from '@/assets/icons/add-square.svg?raw'
import unlockIcon from '@/assets/icons/unlock.svg?raw'
import lockedIcon from '@/assets/icons/locked.svg?raw'
import ellipseIcon from '@/assets/icons/ellipse-icon.svg?raw'
import clipBoardIcon from '@/assets/icons/copy-to-clipboard-icon.svg?raw'
import peopleIcon from '@/assets/icons/people.svg?raw'
import editAltIcon from '@/assets/icons/edit-2.svg?raw'
import graphIcon from '@/assets/icons/graph.svg?raw'
import timerIcon from '@/assets/icons/timer-icon.svg?raw'
import play from '@/assets/icons/play.svg?raw'
import pause from '@/assets/icons/pause.svg?raw'
import sendIcon from '@/assets/icons/sendIcon.svg?raw'
import merge from '@/assets/icons/merge.svg?raw'
import microscope from '@/assets/icons/microscope.svg?raw'
import box from '@/assets/icons/box.svg?raw'
import menu from '@/assets/icons/menu.svg?raw'
import message from '@/assets/icons/message.svg?raw'
import metricsIcon from '@/assets/icons/metrics-icon.svg?raw'
import arrowCircleRightOutline from '@/assets/icons/arrow-circle-right-outline-invert.svg?raw'
import doubleArrowUp from '@/assets/icons/double-arrow-up.svg?raw'
import checkCircle from '@/assets/icons/check-circle.svg?raw'
import minusCircle from '@/assets/icons/minus-circle.svg?raw'
import teacher from '@/assets/icons/teacher.svg?raw'
import truck from '@/assets/icons/truck.svg?raw'
import shieldSearch from '@/assets/icons/shield-search.svg?raw'
import clock from '@/assets/icons/clock.svg?raw'
import comment from '@/assets/icons/comment.svg?raw'
import searchStatus from '@/assets/icons/search-status-icon.svg?raw'
import gridIcon from '@/assets/icons/grid-icon.svg?raw'
import danger from '@/assets/icons/danger.svg?raw'
import zoomIn from '@/assets/icons/zoomInIcon.svg?raw'
import zoomOut from '@/assets/icons/zoomOutIcon.svg?raw'
import colResizer from '@/assets/icons/colResizer.svg?raw'
import dot from '@/assets/icons/dot.svg?raw'
import template from '@/assets/icons/save-add.svg?raw'
import tagRight from '@/assets/icons/tag-right.svg?raw'
import percentageSquare from '@/assets/icons/percentage-square.svg?raw'

export { plugin as formKitPlugin } from '@formkit/vue'
import { createAutoHeightTextareaPlugin } from '@formkit/addons'

export const formKitConfig = defaultConfig({
  locales: { de }, // en locale is included by default
  locale: 'de',
  plugins: [createAutoHeightTextareaPlugin()],
  icons: {
    email,
    password,
    search,
    reorder,
    settings,
    people,
    share,
    linkExternal,
    link,
    addIcon,
    homeIcon,
    dashboardIcon,
    strategyIcon,
    improvementIcon,
    reportingIcon,
    fileSearchIcon,
    fileEditIcon,
    configurationIcon,
    helpIcon,
    generalDataIcon,
    tools,
    list,
    close,
    dialogClose,
    hamburgerIcon,
    logOutIcon,
    editIcon,
    inboxIcon,
    group,
    file,
    entityIcon,
    datasetIcon,
    upDownArrow,
    caretDown,
    deleteIcon,
    questionnaireIcon,
    reActivateIcon,
    sunIcon,
    moonIcon,
    errorIcon,
    thumbUp,
    infoIcon,
    warningIcon,
    checkIcon,
    circleArrowRightIcon,
    circleArrowRightInvertedIcon,
    calendar,
    reportDownloadIcon,
    notificationIcon,
    maximize,
    caretLeftIcon,
    info,
    fileIcon,
    document,
    documentUpload,
    documentDownload,
    tickCircle,
    closeCircle,
    closeIcon,
    filter,
    arrowUp,
    arrowDown,
    minusSquareIcon,
    accountingIcon,
    exportIcon,
    linearArrowLeft,
    energyIcon,
    waterIcon,
    gasStationIcon,
    refrigerantIcon,
    airplaneIcon,
    accommodationIcon,
    busIcon,
    carIcon,
    homeOfficeIcon,
    shopGoodsIcon,
    creditCardIcon,
    offsetIcon,
    spinner,
    eye,
    download,
    fileDoc,
    zoomIn,
    zoomOut,
    globe,
    profile,
    profile2,
    infoQuestionIcon,
    moreIcon,
    buildingIcon,
    folderIcon,
    smsTracking,
    logo,
    circleArrowRightOutline,
    circleArrowLeftOutline,
    copyIcon,
    tickSquareIcon,
    entityAbstract,
    entityOperation,
    entityProject,
    entityOrg,
    entityTenant,
    dataTreeIcon,
    addSquareIcon,
    unlockIcon,
    lockedIcon,
    ellipseIcon,
    clipBoardIcon,
    peopleIcon,
    editAltIcon,
    graphIcon,
    timerIcon,
    play,
    pause,
    sendIcon,
    merge,
    microscope,
    box,
    menu,
    message,
    metricsIcon,
    arrowCircleRightOutline,
    doubleArrowUp,
    checkCircle,
    minusCircle,
    teacher,
    truck,
    shieldSearch,
    clock,
    comment,
    searchStatus,
    gridIcon,
    danger,
    colResizer,
    dot,
    template,
    tagRight,
    percentageSquare,
  },
})
