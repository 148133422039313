<script setup lang="ts">
withDefaults(defineProps<{
  disabled?: boolean
  active?: boolean
  title?: string
}>(), { disabled: false,active: false,title: '', })

const emit = defineEmits(['click'])
</script>

<template>
  <button
    class="w-7 h-7 p-0.5 bg-white rounded hover:bg-gray-light dark:bg-black-soft dark:fill-white dark:hover:bg-blue dark:border dark:border-gray-light"
    :class="{ 'fill-blue dark:bg-blue': active, 'fill-gray opacity-50 pointer-events-none': disabled }"
    :disabled="disabled"
    :title="title"
    @click="emit('click', $event)"
  >
    <slot>?</slot>
  </button>
</template>
