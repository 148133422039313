import axios from 'axios'
import { type Ref } from 'vue'
import { useStorage } from '@vueuse/core'

type TokenStore = {
  access: string
  email: string
  exp: number | null
}
const DEFAULT_AUTH_STORE: TokenStore = {
  access: '',
  email: '',
  exp: null,
}

export const tokenStore: Ref<TokenStore> = useStorage('accessToken', DEFAULT_AUTH_STORE)

export const authlessAxios = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    authorization: undefined,
  },
})

export const defaultPageSize = 100

const apiUrls = [
  null, //v0?
  import.meta.env.VITE_BASE_URL, //v1
  import.meta.env.VITE_BASE_V2_URL, //v2
  import.meta.env.VITE_BASE_V3_URL, //v3
]

axios.defaults.baseURL = apiUrls[1]
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Authorization'] = `Bearer ${tokenStore.value?.access}`

function setToken(access: string): void {
  const tokenPayload = access.split('.')[1]
  const { email, exp } = JSON.parse(atob(tokenPayload))
  tokenStore.value = { access, email, exp }
  axios.defaults.headers.common['Authorization'] = `Bearer ${access}`
}

function parseParams(params: Record<string, any>) {
  // TODO: encodeUriComponent?
  const paramList = Object.keys(params).map((key) => {
    const value = params[key]
    return `${key}=${value}`
  })
  return `?${paramList.join('&')}`
}

function apiUrl(url: string, version: number, params?: Record<string, any>) {
  const paramString = params ? parseParams(params) : ''
  if (url.startsWith('http://') || url.startsWith('https://')) return `${url}${paramString}`
  // return url preceded by api url, after replacing multiple slashes (like //) except for ://
  return `${apiUrls[version]}${url}${paramString}`.replace(/(?<!:)\/+/gm, '/')
}

export async function apiFetch<T>(url: string, params?: Record<string, any>, version = 1, noAuth = false): Promise<T> {
  const client = noAuth ? authlessAxios : axios
  const response = await client.get<T>(apiUrl(url, version, params))
  return response.data
}

export async function apiUpdate<T>(url: string, payload: any, version = 1, noAuth = false): Promise<T> {
  const client = noAuth ? authlessAxios : axios
  const response = await client.patch<T>(apiUrl(url, version), payload)
  return response.data
}

export async function apiCreate<T>(url: string, payload: any, version = 1, noAuth = false, usePut = false): Promise<T> {
  const client = noAuth ? authlessAxios : axios
  const method = usePut ? client.put : client.post
  const response = await method<T>(apiUrl(url, version), payload)
  return response.data
}

export async function apiDelete<T>(url: string, version = 1, noAuth = false, payload?: any) {
  const client = noAuth ? authlessAxios : axios
  const response = await client.delete<T>(apiUrl(url, version), payload)
  return response
}

export async function apiOptions<T>(url: string, version = 1, noAuth = false) {
  const client = noAuth ? authlessAxios : axios
  const response = await client.options<T>(apiUrl(url, version))
  return response
}

export type LoginCredentials =
  | string
  | {
      email: string
      password: string
    }

export async function login(credentials: LoginCredentials): Promise<void> {
  let url, data

  if (typeof credentials === 'string') {
    url = import.meta.env.VITE_TOKEN_LOGIN_URL
    data = { token: credentials }
  } else {
    url = import.meta.env.VITE_LOGIN_URL
    data = credentials
  }

  const response = await axios.post(url, data, { withCredentials: true })
  const { access } = response.data as Record<string, string>
  setToken(access)
}

export async function refreshAccess() {
  try {
    const response = await axios.post(
      import.meta.env.VITE_REFRESH_URL,
      { refresh: 'stored-in-cookie' },
      { withCredentials: true },
    )
    const { access } = response.data as Record<string, string>
    setToken(access)
    console.debug('access token refreshed')
  } catch {
    console.error('Unable to refresh access token')
    return Promise.reject()
  }
}

export function logout(): void {
  delete axios.defaults.headers.common['Authorization']
  tokenStore.value = DEFAULT_AUTH_STORE
}

axios.interceptors.response.use(
  // Responses with HTTP status 2XX don't need special handling (yet?)
  // TODO: might be useful for logging?!
  undefined,

  // Responses with HTTP error status (basically !2XX) will be intercepted by
  // this function. It tries to refresh the token first and logs out on failure
  async (errorResponse) => {
    console.warn('error response intercepted', errorResponse)

    if (errorResponse.response === undefined) {
      return Promise.reject(errorResponse)
    }

    const { status, config } = errorResponse.response
    const { url } = config as Record<string, string>
    const refreshUrl = import.meta.env.VITE_REFRESH_URL

    // If the auth token is invalid, try to refresh it
    // To avoid an endless loop, we check that the current request is not to the refresh URL.
    // To ensure that every request is only retried once, we set a flag on the config upon retrying.
    if (status === 401 && !url.includes(refreshUrl) && !config.hasBeenRetried) {
      // but it might still be refreshable
      try {
        await refreshAccess()
      } catch {
        logout()
        location.reload()
      }

      // refresh successful, lets retry the last request
      // but overwrite the Auth header with the new token
      config.headers['Authorization'] = axios.defaults.headers.common['Authorization']
      config.hasBeenRetried = true
      if (config) return axios(config)
    }

    // in case the above failed or it is not an auth issue, simply relay the error
    return Promise.reject(errorResponse)
  },
)
