export function handleErrors(err: any, s3Upload = false) {
  if (err.response) {
    const response = err.response
    if (err.response.data?.code) {
      if (err.response.data?.code === 'duplicate_upload') {
        return { status: 'uploadFailureDueToDuplicate', response }
      }
      if (err.response.data?.code === 'unsupported_file_type') {
        return { status: 'uploadFailureDueToUnsupportedFileType', response }
      }
      return { status: 'uploadFailureGeneral', response }
    }
    if (err.response.data?.file) return { status: 'uploadFailure', response }
    if (err.response.data?.field_errors) return { status: 'fieldErrors', response }
    if (err.response.data?.worksheet_errors) return { status: 'worksheetErrors', response }
  }
  if (err.message.includes('Network Error')) return { status: 'uploadFailureGeneral' }
  return s3Upload ? { status: 's3UploadFailure', response: err } : { status: 'uploadFailureGeneral' }
}
