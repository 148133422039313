import type { User } from '@store/user'
import { useDebounceFn } from '@vueuse/core'
import { getUsers } from '@api/user'
import { updateReportingRequirement } from '@api/report/requirements'
import { useReportingEntitiesStore } from '@store/reportingEntities'
import { useUserManagementStore } from '@store/userManagement'
import { type ReportingRequirement, useReportingRequirementStore } from '@store/reporting/requirements'
import type { Dataset } from '@store/dataset'
import { success, failure } from '@util/types'

type DataSpec = ReportingRequirement['data_specification']['data']
type Disclosure = ReportingRequirement['disclosure']

function requirementSpec2QuestionnaireField(spec: DataSpec, disclosure: Disclosure): QuestionnaireField {
  const value = disclosure?.data

  switch (spec.type) {
    case 'number':
      return { label: '', value: value ?? 0, inputType: 'number' }
    case 'integer':
      return { label: '', value: value ?? 0, inputType: 'number', integer: true }
    case 'boolean':
      return { label: '', value, inputType: 'boolean' }
    case 'string':
      return {
        label: '',
        value: value ?? '',
        inputType: spec.widget === 'textarea' ? 'rich-text' : 'string',
      }
    case 'select':
      return {
        label: '',
        title: spec.title,
        title_de: spec.title_de,
        choices: value?.choices ?? [],
        values: spec.choices,
        inputType: 'select',
        multiple: spec.multiple,
      }
    case 'table': {
      let tempRows = 0
      const rows = spec.columns.reduce((acc, column, i) => {
        const values = value ? value.columns[i] : column.default.length ? column.default : tempRows
        if (!values) {
          return tempRows
        }
        if (Array.isArray(values) && values.length >= column.default.length && acc <= values.length) {
          tempRows = tempRows >= values.length ? tempRows : values.length
          return tempRows
        }
        if (acc < column.default.length) {
          tempRows = tempRows >= column.default.length ? tempRows : column.default.length
          return tempRows
        }
        if (tempRows >= acc) {
          return tempRows
        } else {
          return acc
        }
      }, 1)

      const columns = spec.columns.map((col) => ({
        ...col,
        type: col.type === 'integer' ? 'number' : col.type,
        integer: col.type === 'integer' || undefined,
      }))

      const values = value?.columns ?? spec.columns.map(() => [] as (string | number | boolean)[])

      return {
        label: '',
        inputType: 'table',
        columns,
        values,
        rows,
        allowMoreRows: spec.allow_more_rows,
        allowMoreColumns: spec.allow_more_columns,
        additionalHeaders: value?.additional_headers ?? [],
      }
    }
    case 'none':
      return { label: '', value: null, inputType: 'none' }
    default:
      console.warn(`⚠ unhandled requirement type! Rendering simple input as fallback.`, spec)
      return { label: '', value: value ?? '', inputType: 'string' }
  }
}

export function requirements2questionnaires(
  reportId: string,
  requirements: ReportingRequirement[],
): QuestionnaireConfig[] {
  const questionnaires: Record<string, QuestionnaireConfig> = {}

  requirements.forEach((requirement) => {
    const {
      id,
      title,
      disclosure,
      description,
      reporting_standard_id,
      section_id,
      order,
      reference_code,
      reference_slug,
      guidance_text,
      regulatory_text,
      help_text,
      children,
      parent,
      is_mandatory,
      is_live,
      enable_attachments,
      prompt_id,
      tags,
    } = requirement

    if (questionnaires[id] === undefined) {
      questionnaires[id] = {
        id,
        title,
        description,
        disclosure,
        reportingStandard: reporting_standard_id,
        sectionId: section_id,
        referenceCode: reference_code,
        referenceSlug: reference_slug,
        fields: [],
        reportId,
        order,
        guidance_text,
        regulatory_text,
        help_text,
        children: requirements2questionnaires(reportId, children),
        parent,
        is_mandatory,
        is_live,
        enable_attachments,
        prompt_id,
        tags,
      }
    }

    questionnaires[id].fields.push(
      requirementSpec2QuestionnaireField(requirement.data_specification.data, requirement.disclosure),
    )
  })

  return Object.values(questionnaires).sort((a, b) => a.order - b.order)
}

export function convertRequirement(requirement: ReportingRequirement, reportId: string): QuestionnaireConfig {
  const {
    id,
    title,
    disclosure,
    description,
    reporting_standard_id,
    section_id,
    order,
    reference_code,
    reference_slug,
    guidance_text,
    regulatory_text,
    help_text,
    children,
    parent,
    is_mandatory,
    is_live,
    enable_attachments,
    prompt_id,
    tags,
  } = requirement

  const convertedRequirement: QuestionnaireConfig = {
    id,
    title,
    description,
    disclosure,
    reportingStandard: reporting_standard_id,
    sectionId: section_id,
    referenceCode: reference_code,
    referenceSlug: reference_slug,
    fields: [],
    reportId,
    order,
    guidance_text,
    regulatory_text,
    help_text,
    children: requirements2questionnaires(reportId, children),
    parent,
    is_mandatory,
    is_live,
    enable_attachments,
    prompt_id,
    tags,
  }

  convertedRequirement.fields.push(
    requirementSpec2QuestionnaireField(requirement.data_specification.data, requirement.disclosure),
  )

  return convertedRequirement
}

type RequirementIds = {
  id: number
  children: RequirementIds[]
}

export async function updateRequirement(
  reportId: string,
  requirementId: number,
  update: any,
  parentId?: number | null,
) {
  try {
    const response = await updateReportingRequirement(reportId, requirementId, update)
    const requirementStore = useReportingRequirementStore()
    requirementStore.updateDisclosureOnReportingRequirement(requirementId, response.disclosure, parentId)
    return response.disclosure
  } catch (error) {
    console.error('Error creating/updating requirement', requirementId, error)
  }
}

export async function updateRequirementWithChildren(
  reportId: string,
  requirement: RequirementIds,
  update: any,
  parentId?: number | null,
  reference?: string,
) {
  const id = requirement.id
  let skipUpdate = false
  // recursively include all the child requirements as well
  // but do it in the background, without await
  requirement.children.forEach((child) => updateRequirementWithChildren(reportId, child, update, id, 'child'))

  const requirementStore = useReportingRequirementStore()
  const requirementItem =
    reference !== 'child'
      ? requirementStore.data.find((item) => item.id === id)
      : requirementStore.data.find((item) => item.id === parentId)?.children.find((item2) => item2.id === id)
  if (requirementItem) {
    const isParent = !requirementItem.parent
    if (isParent && requirementItem.children.length > 0) {
      const isSomeChildIncluded = requirementItem.children
        .filter((child: any) => !requirement.children.map((r) => r.id).includes(child.id))
        .some((child: any) => child.disclosure?.is_included_in_report)

      if (
        !isSomeChildIncluded &&
        requirementItem.disclosure?.is_included_in_report &&
        update.is_included_in_report !== undefined &&
        !update.is_included_in_report
      ) {
        update.is_included_in_report = false
      } else {
        if (!update.completion_status) skipUpdate = true
      }
    } else if (!requirementItem.children.length && requirementItem.disclosure) {
      if (
        (update.is_included_in_report !== null || update.is_included_in_report !== undefined) &&
        update.is_included_in_report === requirementItem.disclosure?.is_included_in_report
      ) {
        skipUpdate = true
      }
    }
  }
  if (skipUpdate) return
  try {
    const response = await updateReportingRequirement(reportId, id, update)

    if (parentId) {
      const parentItem = requirementStore.data.find((parent) => parent.id === parentId)
      if (parentItem) {
        const isSomeChildIncluded = parentItem.children.some(
          (child) => child.id !== id && child.disclosure && child.disclosure.is_included_in_report,
        )

        if (!isSomeChildIncluded && !parentItem.disclosure?.is_included_in_report && update.is_included_in_report) {
          const parentResponse = await updateReportingRequirement(reportId, parentId, { is_included_in_report: true })
          requirementStore.updateDisclosureOnReportingRequirement(parentId, parentResponse.disclosure)
        }
      }
    }
    requirementStore.updateDisclosureOnReportingRequirement(id, response.disclosure, parentId)
    return response.disclosure
  } catch (error) {
    console.error('Error creating/updating requirement', requirement, error)
  }
}

function initials(user: Pick<User, 'first_name' | 'last_name'>) {
  const first = user.first_name[0] || ''
  const second = user.last_name[0] || ''

  return `${first}${second}`.toUpperCase()
}

export type UserMetaData = {
  email: string
  initials: string
  external: boolean
}
export type FormattedUser = LabeledValueWithMeta<string, UserMetaData>

export function formatUser(
  user: Pick<User, 'id' | 'first_name' | 'last_name' | 'email' | 'is_external'>,
): FormattedUser {
  return {
    label: `${user.first_name} ${user.last_name}`,
    value: user.id,
    meta: {
      email: user.email,
      initials: initials(user),
      external: user.is_external,
    },
  }
}

const debouncedGetUsers = useDebounceFn(async (entity: string, search: string) => {
  return await getUsers({
    reporting_entity: entity,
    search,
  })
}, 200)

export async function fetchUsers(search?: string) {
  const { current: selectedEntity } = useReportingEntitiesStore()
  if (!selectedEntity) return []

  if (!search || !search.trim().length) {
    const requirementStore = useReportingRequirementStore()
    if (requirementStore.users === null) await requirementStore.fetchUsers(selectedEntity.id, search ?? '')
    return (requirementStore.users as User[]).slice(0, 10).map((user) => formatUser(user))
  }

  const response = await debouncedGetUsers(selectedEntity.id, search ?? '')
  return response.results.slice(0, 10).map((user) => formatUser(user))
}

export async function fetchUser(userId: string): Promise<FormattedUser | null> {
  const userMgmtStore = useUserManagementStore()
  try {
    const user = await userMgmtStore.fetchUser(userId)
    return formatUser(user)
  } catch {
    return null
  }
}

export async function fetchUserResult(userId: string): Promise<Result<FormattedUser>> {
  const userMgmtStore = useUserManagementStore()
  try {
    const user = await userMgmtStore.fetchUser(userId)
    return success(formatUser(user))
  } catch (err) {
    return failure(err as Error)
  }
}

export function getStartDate(datasets: Dataset[], selectedIds: string[]) {
  let startDate: string = ''
  datasets.forEach((dataset) => {
    if (selectedIds.indexOf(dataset.id) === -1) return
    const newStartDate = dataset.reporting_period.start_date
    if (!startDate || newStartDate < startDate) startDate = newStartDate
  })
  return startDate
}

export function getEndDate(datasets: Dataset[], selectedIds: string[]) {
  let endDate: string = ''
  datasets.forEach((dataset) => {
    if (selectedIds.indexOf(dataset.id) === -1) return
    const newEndDate = dataset.reporting_period.end_date
    if (!endDate || newEndDate > endDate) endDate = newEndDate
  })
  return endDate
}
