import type { Dataset } from '@/stores/dataset'
import type { ChartData } from '@component/Chart.vue'
import type { TranslationFunctions } from '@i18n/i18n-types'
import { isAfter } from 'date-fns'
import type { Ref } from 'vue'

export type ChartType = 'bar' | 'pie' | 'donut'

export type GRIScope =
  | 'gri-305-1'
  | 'gri-305-2'
  | 'gri-305-3'
  | 'gri-302-1'
  | 'gri-306-5'
  | 'gri-301-1'
  | 'gri-303-3'
  | 'gri-303-4'
  | 'gri-305-5'
export const GRIScopes: { [key in GRIScope]: ChartType } = {
  'gri-305-1': 'bar',
  'gri-305-2': 'bar',
  'gri-305-3': 'bar',
  'gri-302-1': 'pie',
  'gri-306-5': 'pie',
  'gri-301-1': 'donut',
  'gri-303-3': 'pie',
  'gri-303-4': 'pie',
  'gri-305-5': 'donut',
}

export function isGRIScope(scope: string): scope is GRIScope {
  return Object.keys(GRIScopes).includes(scope as GRIScope)
}

export type EmissionScope = GRI.GRI3051Data | GRI.GRI3052Data | GRI.GRI3053Data
export const EmissionScopes: GRIScope[] = ['gri-305-1', 'gri-305-2', 'gri-305-3']
export const EmissionThreshholds = {
  warn: 100,
  crit: 1000,
}

export function datasetSupportsAggregation(dataset: Dataset) {
  // this is the date we agree on here:
  // https://www.notion.so/codegaia/Migration-Changelog-v2-to-v3-71837f5410ac48319fcb8b5c84881679
  const cutoffDate = '2024-01-20T10:50:00Z'
  return !dataset.closed || (dataset.closed_at && isAfter(dataset.closed_at, cutoffDate))
}
export function getApiVersion(dataset: Dataset) {
  return datasetSupportsAggregation(dataset) ? '3' : '2'
}

export function isAggregating(datasetIds: string[], dataset: Dataset | null) {
  return (
    dataset !== null &&
    (datasetIds.length > 1 ||
      (datasetIds.length === 1 && datasetIds[0] !== dataset.id) ||
      (datasetIds.length === 0 && datasetSupportsAggregation(dataset)))
  )
}

type GRIParamsV2 = {
  version: '2'
  startDate: string
  endDate: string
  datasetId: string
}
type GRIParamsV3 = {
  version: '3'
  startDate: string
  endDate: string
  referenceReportingEntityId: string
  datasetIds: string[]
}
export type GRIParams = GRIParamsV2 | GRIParamsV3

export function buildGriParams(
  dataset: Dataset,
  referenceReportingEntityId: string,
  startDate: string,
  endDate: string,
): GRIParams {
  const version = getApiVersion(dataset)
  if (version === '2') {
    return {
      version,
      startDate,
      endDate,
      datasetId: dataset.id,
    }
  }
  return {
    version,
    startDate,
    endDate,
    referenceReportingEntityId,
    datasetIds: [dataset.id],
  }
}

export function buildAggregationParams(
  datasetIds: string[],
  referenceReportingEntityId: string,
  startDate: string,
  endDate: string,
): GRIParamsV3 {
  return {
    version: '3',
    startDate,
    endDate,
    referenceReportingEntityId,
    datasetIds,
  }
}

export type GRIData =
  | GRI.GRI3051Data
  | GRI.GRI3052Data
  | GRI.GRI3053Data
  | GRI.GRI3021Data
  | GRI.GRI3065Data
  | GRI.GRI3011Data
  | GRI.GRI3033Data
  | GRI.GRI3034Data
  | GRI.GRI3055Data

export function griDataParser(data: GRIData, scope: GRIScope, LL: Ref<TranslationFunctions>): GRI.LabeledGriData {
  let title: string
  let subTitle = ''
  let totals: GRI.LabeledQuantity[]
  let chart: { chartData: number[]; chartLabels: string[] }

  // prefix for translation keys
  const griNS = LL.value.performance.gri

  switch (scope) {
    case 'gri-305-1':
      title = griNS.ghgTitle()
      subTitle = griNS.scope1Title()
      totals = [
        {
          label: griNS.scope1Total(),
          ...(data as GRI.GRI3051Data).a.total_emissions,
        },
      ]
      chart = {
        chartLabels: [griNS.total(), griNS.biogenic()],
        chartData: [
          (data as GRI.GRI3051Data).a.total_emissions.value,
          (data as GRI.GRI3051Data).c.total_emissions.value,
        ],
      }
      break

    case 'gri-305-2':
      title = griNS.ghgTitle()
      subTitle = griNS.scope2Title()
      totals = [
        {
          label: griNS.scope2Total(),
          ...(data as GRI.GRI3052Data).b.total_emissions,
        },
      ]
      chart = {
        chartLabels: [griNS.market(), griNS.location()],
        chartData: [
          (data as GRI.GRI3052Data).b.total_emissions.value,
          (data as GRI.GRI3052Data).a.total_emissions.value,
        ],
      }
      break

    // this case statement bracket is INTENTIONAL!
    // see https://eslint.org/docs/latest/rules/no-case-declarations
    case 'gri-305-3': {
      title = griNS.ghgTitle()
      subTitle = griNS.scope3Title()

      const t1 = (data as GRI.GRI3053Data).a.total_emissions.value
      const supplyChain = (data as GRI.GRI3053Data).supply_chain

      totals = [
        {
          label: griNS.scope3Activties(),
          value: supplyChain.total_spend_based_emissions.value + t1,
          unit: supplyChain.total_spend_based_emissions.unit,
        },
      ]
      chart = {
        chartLabels: [griNS.activity(), griNS.biogenicActivity(), griNS.supplyChain(), griNS.supplyChain2()],
        chartData: [
          (data as GRI.GRI3053Data).a.total_emissions.value,
          (data as GRI.GRI3053Data).c.total_emissions.value,
          (data as GRI.GRI3053Data).supply_chain.total_spend_based_emissions.value,
        ],
      }
      break
    }

    case 'gri-302-1':
      title = griNS.energyConsumption()

      totals = [
        {
          label: griNS.energyConsumptionTotal(),
          ...(data as GRI.GRI3021Data).e.total_consumption,
        },
      ]
      chart = {
        chartLabels: [griNS.fuels(), griNS.electricity(), griNS.heat(), griNS.cooling(), griNS.steam()],
        chartData: [
          (data as GRI.GRI3021Data).a.total_consumption.value,
          // following values come in kWh, which times 3.6 becomes megajoule
          (data as GRI.GRI3021Data).c.electricity.value * 3.6,
          (data as GRI.GRI3021Data).c.heat.value * 3.6,
          (data as GRI.GRI3021Data).c.cooling.value * 3.6,
          (data as GRI.GRI3021Data).c.steam.value * 3.6,
        ],
      }

      break

    case 'gri-306-5':
      title = griNS.wasteDisposal()

      totals = [
        {
          label: griNS.wasteDisposalTotal(),
          ...(data as GRI.GRI3065Data).a.total_disposed_waste,
        },
      ]

      chart = {
        chartLabels: [griNS.nonHazardous(), griNS.hazardous()],
        chartData: [
          (data as GRI.GRI3065Data).b.total_non_hazardous_disposed_waste.value,
          (data as GRI.GRI3065Data).c.total_hazardous_disposed_waste.value,
        ],
      }

      break

    case 'gri-301-1':
      title = griNS.materialsUsed()

      totals = [
        {
          label: griNS.materialsUsedTotal(),
          ...(data as GRI.GRI3011Data).total_materials,
        },
      ]

      chart = {
        chartLabels: [griNS.nonRenewable(), griNS.renewable()],
        chartData: [
          (data as GRI.GRI3011Data).a.total_non_renewable_materials.value,
          (data as GRI.GRI3011Data).a.total_renewable_materials.value,
        ],
      }

      break

    case 'gri-303-3':
      title = griNS.waterWithdrawn()

      totals = [
        {
          label: griNS.waterWithdrawnTotal(),
          ...(data as GRI.GRI3033Data).a.total,
        },
      ]

      chart = {
        chartLabels: [griNS.thirdPartyWater()],
        chartData: (data as GRI.GRI3033Data).a.by_sources.map((src) => src.total.value),
      }

      break

    case 'gri-303-4':
      title = griNS.waterDischarged()

      totals = [
        {
          label: griNS.waterDischargedTotal(),
          ...(data as GRI.GRI3034Data).a.total,
        },
      ]

      chart = {
        chartLabels: [griNS.thirdPartyWater()],
        chartData: (data as GRI.GRI3034Data).a.by_destination.map((src) => src.total.value),
      }

      break

    case 'gri-305-5':
      title = griNS.emissionReduction()

      totals = [
        {
          label: griNS.emissionReductionTotal(),
          ...(data as GRI.GRI3055Data).a.total_reductions,
        },
      ]

      chart = {
        chartLabels: [griNS.reductions()],
        chartData: [(data as GRI.GRI3055Data).a.total_reductions.value],
      }

      break

    default:
      title = ''
      subTitle = ''
      totals = []
      chart = {
        chartData: [],
        chartLabels: [],
      }
  }

  const isEmpty = chart.chartData.every((value) => value === 0)

  return { title, subTitle, totals, chart, isEmpty }
}

export function griSetTitleOnError(scope: GRIScope, LL: Ref<TranslationFunctions>) {
  let title: string
  const error = true
  // prefix for translation keys
  const griNS = LL.value.performance.gri

  switch (scope) {
    case 'gri-305-1':
      title = griNS.ghgTitle()
      break

    case 'gri-305-2':
      title = griNS.ghgTitle()
      break

    case 'gri-305-3':
      title = griNS.ghgTitle()
      break

    case 'gri-302-1':
      title = griNS.energyConsumption()
      break

    case 'gri-306-5':
      title = griNS.wasteDisposal()
      break

    case 'gri-301-1':
      title = griNS.materialsUsed()
      break

    case 'gri-303-3':
      title = griNS.waterWithdrawn()
      break

    case 'gri-303-4':
      title = griNS.waterDischarged()
      break

    case 'gri-305-5':
      title = griNS.emissionReduction()
      break

    default:
      title = ''
  }

  return { title, error }
}

export type Detail = {
  scope: string
  title: string
  label: string
  info: string
  total: GRI.Quantity
  chartData: ChartData
  chartLabels: string[]
  deprecated?: boolean
}

export function griDetailParser(data: GRIData, scope: GRIScope, LL: Ref<TranslationFunctions>): Detail[] {
  const details: Detail[] = []

  switch (scope) {
    case 'gri-305-1': {
      const details1 = (data as GRI.GRI3051Data).a.emissions_by_source
      const chartLabels1 = details1.map((item) => item.source)
      const chartData1 = details1.map(({ emissions }) => ({
        value: emissions.value,
        meta: emissions.unit,
      }))

      details.push({
        scope: 'GRI-305-1-a',
        title: LL.value.performance.gri3051a.title(),
        label: LL.value.performance.gri.emissionsBySource(),
        info: LL.value.performance.gri3051a.info(),
        total: (data as GRI.GRI3051Data).a.total_emissions,
        chartData: chartData1,
        chartLabels: chartLabels1,
      })

      const details2 = (data as GRI.GRI3051Data).c.emissions_by_source
      const chartLabels2 = details2.map((item) => item.source)
      const chartData2 = details2.map(({ emissions }) => ({
        value: emissions.value,
        meta: emissions.unit,
      }))

      details.push({
        scope: 'GRI-305-1-c',
        title: LL.value.performance.gri3051c.title(),
        label: LL.value.performance.gri.emissionsBySource(),
        info: LL.value.performance.gri3051c.info(),
        total: (data as GRI.GRI3051Data).c.total_emissions,
        chartData: chartData2,
        chartLabels: chartLabels2,
      })
      break
    }
    case 'gri-305-2': {
      const details1 = (data as GRI.GRI3052Data).a.emissions_by_region
      const chartLabels1 = details1.map((item) => item.region)
      const chartData1 = details1.map(({ emissions }) => ({
        value: emissions.value,
        meta: emissions.unit,
      }))

      details.push({
        scope: 'GRI-305-2-a',
        title: LL.value.performance.gri3052a.title(),
        label: LL.value.performance.gri.emissionsByRegion(),
        info: LL.value.performance.gri3052a.info(),
        total: (data as GRI.GRI3052Data).a.total_emissions,
        chartData: chartData1,
        chartLabels: chartLabels1,
      })

      const details2 = (data as GRI.GRI3052Data).b.emissions_by_energy_type
      const chartLabels2 = details2.map((item) => item.energy_type)
      const chartData2 = details2.map(({ emissions }) => ({
        value: emissions.value,
        meta: emissions.unit,
      }))

      details.push({
        scope: 'GRI-305-2-c',
        title: LL.value.performance.gri3052b.title(),
        label: LL.value.performance.gri.emissionsByEnergyType(),
        info: LL.value.performance.gri3052b.info(),
        total: (data as GRI.GRI3052Data).b.total_emissions,
        chartData: chartData2,
        chartLabels: chartLabels2,
      })
      break
    }
    case 'gri-305-3': {
      const detailsGhgp = (data as GRI.GRI3053Data).emissions_by_ghgp_category
      const chartLabelsGhgp = detailsGhgp.map((activitiy) =>
        LL.value.performance.grighgpEmissions.ghgpTitles[
          activitiy.category_number.replace('.', '-') as I18NGHGPCategoryTitle
        ](),
      )
      const chartDataGhgp = detailsGhgp.map(({ emissions, is_implemented }) => ({
        value: emissions.value,
        meta: is_implemented ? emissions.unit : 'no_data',
        isImplemented: is_implemented,
      }))

      details.push({
        scope: '',
        title: LL.value.performance.grighgpEmissions.title(),
        label: LL.value.performance.grighgpEmissions.title(),
        info: '',
        total: {
          value: detailsGhgp
            .filter((activitiy) => activitiy.is_implemented)
            .reduce((acc, { emissions }) => acc + emissions.value, 0),
          unit: detailsGhgp.map(({ emissions }) => emissions.unit)[0],
        },
        chartData: chartDataGhgp,
        chartLabels: chartLabelsGhgp,
      })

      const details1 = (data as GRI.GRI3053Data).a.emissions_by_source
      const chartLabels1 = details1.map((item) => item.source)
      const chartData1 = details1.map(({ emissions }) => ({
        value: emissions.value,
        meta: emissions.unit,
      }))

      details.push({
        scope: 'GRI-305-3-a',
        title: LL.value.performance.gri3053a.title(),
        label: LL.value.performance.gri.emissionsBySource(),
        info: LL.value.performance.gri3053a.info(),
        total: (data as GRI.GRI3053Data).a.total_emissions,
        chartData: chartData1,
        chartLabels: chartLabels1,
      })

      const details2 = (data as GRI.GRI3053Data).c.emissions_by_source
      const chartLabels2 = details2.map((item) => item.source)
      const chartData2 = details2.map(({ emissions }) => ({
        value: emissions.value,
        meta: emissions.unit,
      }))

      details.push({
        scope: 'GRI-305-3-c',
        title: LL.value.performance.gri3053c.title(),
        label: LL.value.performance.gri.emissionsBySource(),
        info: LL.value.performance.gri3053c.info(),
        total: (data as GRI.GRI3053Data).c.total_emissions,
        chartData: chartData2,
        chartLabels: chartLabels2,
      })

      const details3 = (data as GRI.GRI3053Data).supply_chain.spend_based_emissions_by_initiating_sector_or_company
      const chartLabels3 = details3.map((item) => item.sector)
      const chartData3 = details3.map(({ emissions }) => ({
        value: emissions.value,
        meta: emissions.unit,
      }))

      details.push({
        scope: '', // GRI-305-3-supply-chain
        title: LL.value.performance.gri3053SupplyChain.title(),
        label: '',
        info: LL.value.performance.gri3053SupplyChain.info(),
        total: (data as GRI.GRI3053Data).supply_chain.total_spend_based_emissions,
        chartData: chartData3,
        chartLabels: chartLabels3,
      })

      const details4 = (data as GRI.GRI3053Data).supply_chain.tier_2_emissions_by_sector
      const chartLabels4 = details4.map((item) => item.oecd_sector)
      const chartData4 = details4.map(({ emissions }) => ({
        value: emissions.value,
        meta: emissions.unit,
      }))

      details.push({
        scope: '', // GRI-305-3-supply-chain-other
        title: LL.value.performance.gri3053SupplyChainOther.title(),
        label: '',
        info: LL.value.performance.gri3053SupplyChainOther.info(),
        total: (data as GRI.GRI3053Data).supply_chain.total_tier_2_emissions,
        chartData: chartData4,
        chartLabels: chartLabels4,
        deprecated: true,
      })

      const details5 = (data as GRI.GRI3053Data).supply_chain.whole_network_emissions_by_sector
      const chartLabels5 = details5.map((item) => item.oecd_sector)
      const chartData5 = details5.map(({ emissions }) => ({
        value: emissions.value,
        meta: emissions.unit,
      }))

      details.push({
        scope: '', // GRI-305-3-whole-network
        title: LL.value.performance.gri3053WholeNetwork.title(),
        label: '',
        info: LL.value.performance.gri3053WholeNetwork.info(),
        total: (data as GRI.GRI3053Data).supply_chain.total_whole_network_emissions,
        chartData: chartData5,
        chartLabels: chartLabels5,
        deprecated: true,
      })
      break
    }
    case 'gri-302-1': {
      const details1 = (data as GRI.GRI3021Data).e.consumption_by_energy
      const chartLabels1 = [
        LL.value.performance.gri3021e.a(),
        LL.value.performance.gri3021e.b(),
        LL.value.performance.gri3021e.c(),
      ]
      const chartData1 = Object.values(details1).map(({ value, unit }) => ({
        value,
        meta: unit,
      }))

      details.push({
        scope: 'GRI-302-1-e',
        title: LL.value.performance.gri3021e.title(),
        label: '',
        info: LL.value.performance.gri3021e.info(),
        total: (data as GRI.GRI3021Data).e.total_consumption,
        chartData: chartData1,
        chartLabels: chartLabels1,
      })

      const details2 = (data as GRI.GRI3021Data).a.consumption_by_fuel_type
      const chartLabels2 = details2.map(({ fuel_type }) => fuel_type)
      const chartData2 = details2.map(({ energy }) => ({
        value: energy.value,
        meta: energy.unit,
      }))

      details.push({
        scope: 'GRI-302-1-a',
        title: LL.value.performance.gri3021a.title(),
        label: '',
        info: LL.value.performance.gri3021a.info(),
        total: (data as GRI.GRI3021Data).a.total_consumption,
        chartData: chartData2,
        chartLabels: chartLabels2,
      })

      const details3 = (data as GRI.GRI3021Data).b.consumption_by_fuel_type
      const chartLabels3 = details3.map(({ fuel_type }) => fuel_type)
      const chartData3 = details3.map(({ energy }) => ({
        value: energy.value,
        meta: energy.unit,
      }))

      details.push({
        scope: 'GRI-302-1-b',
        title: LL.value.performance.gri3021b.title(),
        label: '',
        info: LL.value.performance.gri3021b.info(),
        total: (data as GRI.GRI3021Data).b.total_consumption,
        chartData: chartData3,
        chartLabels: chartLabels3,
      })

      const details4 = (data as GRI.GRI3021Data).c
      const chartLabels4 = [
        LL.value.performance.gri3021c.a(),
        LL.value.performance.gri3021c.b(),
        LL.value.performance.gri3021c.c(),
        LL.value.performance.gri3021c.d(),
      ]
      const chartData4 = Object.values(details4).map(({ value, unit }) => ({
        value,
        meta: unit,
      }))

      details.push({
        scope: 'GRI-302-1-c',
        title: LL.value.performance.gri3021c.title(),
        label: '',
        info: LL.value.performance.gri3021c.info(),
        total: {
          value: Object.values(details4).reduce((acc, { value }) => acc + value, 0),
          unit: details4.electricity.unit,
        },
        chartData: chartData4,
        chartLabels: chartLabels4,
      })

      const details5 = (data as GRI.GRI3021Data).d
      const chartLabels5 = [
        LL.value.performance.gri3021d.a(),
        LL.value.performance.gri3021d.b(),
        LL.value.performance.gri3021d.c(),
        LL.value.performance.gri3021d.d(),
      ]
      const chartData5 = Object.values(details5).map(({ value, unit }) => ({
        value,
        meta: unit,
      }))

      details.push({
        scope: 'GRI-302-1-d',
        title: LL.value.performance.gri3021d.title(),
        label: '',
        info: LL.value.performance.gri3021d.info(),
        total: {
          value: Object.values(details5).reduce((acc, { value }) => acc + value, 0),
          unit: details5.electricity.unit,
        },
        chartData: chartData5,
        chartLabels: chartLabels5,
      })

      break
    }
    case 'gri-306-5': {
      const details1 = (data as GRI.GRI3065Data).a.disposed_waste_by_composition
      const chartLabels1 = details1.map((item) => item.composition)
      const chartData1 = details1.map(({ mass }) => ({
        value: mass.value,
        meta: mass.unit,
      }))

      details.push({
        scope: 'GRI-306-5-a',
        title: LL.value.performance.gri3065a.title(),
        label: LL.value.performance.gri.wasteByComposition(),
        info: LL.value.performance.gri3065a.info(),
        total: (data as GRI.GRI3065Data).a.total_disposed_waste,
        chartData: chartData1,
        chartLabels: chartLabels1,
      })

      const details2 = (data as GRI.GRI3065Data).b.non_hazardous_disposed_waste_by_operation
      const chartLabels2 = details2.map((item) => item.operation)
      const chartData2 = details2.map(({ total }) => ({
        value: total.value,
        meta: total.unit,
      }))

      details.push({
        scope: 'GRI-306-5-b',
        title: LL.value.performance.gri3065b.title(),
        label: LL.value.performance.gri.wasteByDisposalMethod(),
        info: LL.value.performance.gri3065b.info(),
        total: (data as GRI.GRI3065Data).b.total_non_hazardous_disposed_waste,
        chartData: chartData2,
        chartLabels: chartLabels2,
      })

      const details3 = (data as GRI.GRI3065Data).c.hazardous_disposed_waste_by_operation
      const chartLabels3 = details3.map((item) => item.operation)
      const chartData3 = details3.map(({ total }) => ({
        value: total.value,
        meta: total.unit,
      }))

      details.push({
        scope: 'GRI-306-5-c',
        title: LL.value.performance.gri3065c.title(),
        label: LL.value.performance.gri.wasteByDisposalMethod(),
        info: LL.value.performance.gri3065c.info(),
        total: (data as GRI.GRI3065Data).c.total_hazardous_disposed_waste,
        chartData: chartData3,
        chartLabels: chartLabels3,
      })

      break
    }
    case 'gri-301-1': {
      const detail = (data as GRI.GRI3011Data).a
      const renewable = detail.total_renewable_materials
      const nonRenewable = detail.total_non_renewable_materials

      const chartLabels1 = [LL.value.performance.gri.nonRenewable(), LL.value.performance.gri.renewable()]
      const chartData1 = [
        { value: nonRenewable.value, meta: nonRenewable.unit },
        { value: renewable.value, meta: renewable.unit },
      ]

      details.push({
        scope: 'GRI-301-1-a',
        title: LL.value.performance.gri3011a.title(),
        label: '',
        info: LL.value.performance.gri3011a.info(),
        total: (data as GRI.GRI3011Data).total_materials,
        chartData: chartData1,
        chartLabels: chartLabels1,
      })

      const nonRenewableMaterials = detail.non_renewable_materials_by_category
      details.push({
        scope: 'GRI-301-1-a-i',
        title: LL.value.performance.gri3011ai.title(),
        label: '',
        info: LL.value.performance.gri3011ai.info(),
        total: nonRenewable,
        chartData: nonRenewableMaterials.reduce((list: any[], category: GRI.MaterialCategory) => {
          const categoryScope = category.scope
          return [
            ...list,
            { value: categoryScope.sourced.value, meta: categoryScope.sourced.unit },
            { value: categoryScope.purchased.value, meta: categoryScope.purchased.unit },
          ]
        }, []),
        chartLabels: nonRenewableMaterials.reduce((list: any[], category: GRI.MaterialCategory) => {
          return [
            ...list,
            `${LL.value.griActivityContext.sourced()}: ${category.category}`,
            `${LL.value.griActivityContext.purchased()}: ${category.category}`,
          ]
        }, []),
      })

      const renewableMaterials = detail.renewable_materials_by_category
      details.push({
        scope: 'GRI-301-1-a-ii',
        title: LL.value.performance.gri3011aii.title(),
        label: '',
        info: LL.value.performance.gri3011aii.info(),
        total: renewable,
        chartData: renewableMaterials.reduce((list: any[], category: GRI.MaterialCategory) => {
          const categoryScope = category.scope
          return [
            ...list,
            { value: categoryScope.sourced.value, meta: categoryScope.sourced.unit },
            { value: categoryScope.purchased.value, meta: categoryScope.purchased.unit },
          ]
        }, []),
        chartLabels: renewableMaterials.reduce((list: any[], category: GRI.MaterialCategory) => {
          return [
            ...list,
            `${LL.value.griActivityContext.sourced()}: ${category.category}`,
            `${LL.value.griActivityContext.purchased()}: ${category.category}`,
          ]
        }, []),
      })

      break
    }
    case 'gri-303-3': {
      const detail1 = (data as GRI.GRI3033Data).a.by_sources
      const chartLabels1 = detail1.map(({ source }) => source)
      const chartData1 = detail1.map(({ total }) => ({
        value: total.value,
        meta: total.unit,
      }))

      details.push({
        scope: 'GRI-303-3-a',
        title: LL.value.performance.gri3033a.title(),
        label: '',
        info: LL.value.performance.gri3033a.info(),
        total: (data as GRI.GRI3033Data).a.total,
        chartData: chartData1,
        chartLabels: chartLabels1,
      })

      const detail2 = (data as GRI.GRI3033Data).b.by_sources
      const chartLabels2 = detail2.map(({ source }) => source)
      const chartData2 = detail2.map(({ total }) => ({
        value: total.value,
        meta: total.unit,
      }))

      details.push({
        scope: 'GRI-303-3-b',
        title: LL.value.performance.gri3033b.title(),
        label: '',
        info: LL.value.performance.gri3033b.info(),
        total: (data as GRI.GRI3033Data).b.total,
        chartData: chartData2,
        chartLabels: chartLabels2,
      })

      break
    }
    case 'gri-303-4': {
      const detail1 = (data as GRI.GRI3034Data).a.by_destination
      const chartLabels1 = detail1.map(({ destination }) => destination)
      const chartData1 = detail1.map(({ total }) => ({
        value: total.value,
        meta: total.unit,
      }))

      details.push({
        scope: 'GRI-303-4-a',
        title: LL.value.performance.gri3034a.title(),
        label: '',
        info: LL.value.performance.gri3034a.info(),
        total: (data as GRI.GRI3034Data).a.total,
        chartData: chartData1,
        chartLabels: chartLabels1,
      })

      const detail2 = (data as GRI.GRI3034Data).c.by_destination
      const chartLabels2 = detail2.map(({ destination }) => destination)
      const chartData2 = detail2.map(({ total }) => ({
        value: total.value,
        meta: total.unit,
      }))

      details.push({
        scope: 'GRI-303-4-c',
        title: LL.value.performance.gri3034c.title(),
        label: '',
        info: LL.value.performance.gri3034c.info(),
        total: (data as GRI.GRI3034Data).c.total,
        chartData: chartData2,
        chartLabels: chartLabels2,
      })

      break
    }
    case 'gri-305-5': {
      const detail1 = (data as GRI.GRI3055Data).a.reductions_by_initiative.offsets.offsets_by_vendor
      const chartLabels1 = detail1.map(({ vendor }) => vendor)
      const chartData1 = detail1.map(({ quantity }) => ({
        value: quantity.value,
        meta: quantity.unit,
      }))

      details.push({
        scope: 'GRI-305-5-a',
        title: LL.value.performance.gri3055a.title(),
        label: '',
        info: LL.value.performance.gri3055a.info(),
        total: (data as GRI.GRI3055Data).a.total_reductions,
        chartData: chartData1,
        chartLabels: chartLabels1,
      })

      break
    }
  }

  return details
}

export type ParsedActivityData = {
  source: string
  quantity: GRI.Quantity
  emissions: GRI.Quantity
  transactionTime: string
  context: string
  file: {
    id: string
    hash: string
  }
  factor: GRI.Quantity
  factorSource: string | null
  source_deleted: boolean
}

export function griActivityParser(activities: GRI.ActivityEmissionData[]) {
  return activities.map<ParsedActivityData>((activity) => {
    const emissions: GRI.Quantity = {
      value: 0.0,
      unit: 'kilogram',
    }
    const factor: GRI.Quantity = {
      value: 0.0,
      unit: 'kilogram',
    }
    let factorSource = 'N/A'
    if (activity.co2e_emissions) {
      emissions.value = activity.co2e_emissions.value
    } else if (activity.market_based_co2e_emissions) {
      emissions.value = activity.market_based_co2e_emissions.value
    }

    if (activity.co2e_emissions_factor) {
      factor.value = activity.co2e_emissions_factor.value
      factor.unit = activity.co2e_emissions_factor.unit
    }
    if (activity.co2e_emissions_factor_source) {
      factorSource = activity.co2e_emissions_factor_source
    }

    return {
      source: activity.activity,
      quantity: activity.quantity,
      emissions,
      factor,
      factorSource,
      transactionTime: activity.transaction_time,
      context: activity.context,
      file: {
        id: activity.source_file_id,
        hash: activity.source_file_hash,
      },
      source_deleted: activity?.source_deleted ?? false,
    }
  })
}

export function ghgpEmissionsByCategoryParser(categories: GRI.GhgpCategoryItem[]) {
  return categories.filter((category) => category.is_implemented)
}
