<script setup lang="ts">import { computed as _computed } from 'vue';

/**
  Renders a well formatted, HTML compliant datetime or datetime-range
  Uses INTL DateTime Formatting and the proper n-dash for value range separation
 */
import { intlFormat } from 'date-fns'
import useLocales from '@composable/useLocales'

export interface Props {
  value: number | Date | string
  value2?: number | Date | string
  options?: any
}
const props = defineProps<Props>()
const { locale } = useLocales()
const options = _computed(() => ({
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  ...props.options,
}))

const properValue = _computed(() => `${new Date(props.value)}`)
const properValue2 = _computed(() => `${props.value2 && new Date(props.value2)}`)

const formattedValue = _computed(() => {
  return intlFormat(new Date(props.value), options.value, { locale: locale.value })
})

const formattedValue2 = _computed(() => {
  if (!props.value2) return undefined
  return intlFormat(new Date(props.value2), options.value, { locale: locale.value })
})
</script>

<template>
  <time :datetime="properValue">
    <slot>{{ formattedValue }}</slot>
  </time>
  <template v-if="value2">
    &ndash;
    <time :datetime="properValue2">
      {{ formattedValue2 }}
    </time>
  </template>
</template>
