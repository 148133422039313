import { ref, type Ref } from 'vue'
import {
  fetchDisclosureDraftBatches,
  createDisclosureDraftBatch,
  acceptDisclosureDraftBatch,
  declineDisclosureDraftBatch,
  cancelDisclosureDraftBatch,
  type DisclosureDraftBatch,
  type BatchMode,
} from '@api/materiality'

const pollingDelay = 1000 // poll every second
let polling: NodeJS.Timeout
let stopPolling = false
let batchCache: DisclosureDraftBatch | null = null

// global state refs
let loading: Ref<boolean>
let waiting: Ref<boolean>
let ready: Ref<boolean>

const defaultOptions = {
  initImmediately: false,
}

export default function useDisclosureDraftBatches(options = defaultOptions) {
  if (!loading) loading = ref(false)
  if (!waiting) waiting = ref(false)
  if (!ready) ready = ref(false)
  const batch = ref<DisclosureDraftBatch | null>(batchCache)

  function setBatch(newBatch: DisclosureDraftBatch) {
    batchCache = newBatch
    batch.value = newBatch
    waiting.value = newBatch.disclosure_drafts.some((d) => d.request_status === 'Waiting')
    ready.value = newBatch.disclosure_drafts.some((d) => d.request_status === 'Successful')
    stopPolling = !waiting.value
  }

  async function update() {
    loading.value = true
    const response = await fetchDisclosureDraftBatches()
    const openBatches = response.results.filter((b) => b.disclosure_drafts.length > 0)

    if (openBatches.length) {
      // Assuming one batch exists at a time, but that might change in the future,
      // so let's pick the last entry for now, just in case.
      setBatch(openBatches[openBatches.length - 1])
    } else {
      // no batches, no polling ¯\_(ツ)_/¯
      stopPolling = true
      batchCache = null
    }

    loading.value = false
  }

  function poll() {
    if (stopPolling) return
    update()
    polling = setTimeout(poll, pollingDelay)
  }

  function reset() {
    batch.value = null
    batchCache = null
    loading.value = false
    waiting.value = false
    ready.value = false
  }

  async function createBatch(processId: string, reportId: string, mode: BatchMode) {
    if (loading.value || !stopPolling) return

    loading.value = true
    waiting.value = true
    ready.value = false
    stopPolling = false

    const response = await createDisclosureDraftBatch(processId, reportId, mode)
    batch.value = response
    batchCache = response
    polling = setTimeout(poll, pollingDelay)
    loading.value = false
  }

  async function accept(overrides = {} as Record<string, string>) {
    if (!batch.value) return
    await acceptDisclosureDraftBatch(batch.value.id, overrides)
    reset()
  }

  async function decline() {
    if (!batch.value) return
    await declineDisclosureDraftBatch(batch.value.id)
    reset()
  }

  async function cancel() {
    if (!batch.value) return
    await cancelDisclosureDraftBatch(batch.value.id)
    reset()
  }

  function init() {
    if (batchCache === null) update()
    else setBatch(batchCache)

    if (polling === undefined && !stopPolling) poll()
  }

  if (options.initImmediately) init()

  return { init, loading, batch, waiting, ready, createBatch, accept, decline, cancel, reset }
}
