<script setup lang="ts">
import CheckBoxGroup from '@component/forms/CheckBoxGroup.vue'
import AppButton from '@component/forms/AppButton.vue'
import useFeatureFlags from '@composable/useFeatureFlags'

const { flagStore, resetFlagStore } = useFeatureFlags()

function itemsFor(name: keyof typeof flagStore.value) {
  return Object.keys(flagStore.value[name]).map((title) => ({
    id: `${name}/${title}`,
    title,
    value: flagStore.value[name][title],
  }))
}
</script>

<template>
  <CheckBoxGroup
    v-for="(flags, parent) in flagStore"
    class="my-8 text-black dark:text-white"
    :key="parent"
    :parent="parent as string"
    :items="itemsFor(parent)"
    :opened="true"
    @change="flagStore[parent][$event.title] = $event.value"
  />
  <p class="my-2 text-black dark:text-white">
    New feature flags might not show up automatically. In this case you need to reset the feature flags. This will reset
    all flags to their default values.
  </p>
  <AppButton secondary @click="resetFlagStore" label="Reset/Reload Feature Flags" />
</template>
