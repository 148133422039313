import Intercom, { boot, shutdown, update } from '@intercom/messenger-js-sdk'

export interface UserInfo {
  id: UUID
  first_name: string
  email: Email
  date_joined: string
}

const initOpts = {
  app_id: import.meta.env.VITE_INTERCOM_APP_ID,
  region: 'eu' as const,
}

export function initializeIntercom() {
  if (import.meta.env.VITE_ENVIRONMENT === 'development') return
  Intercom(initOpts)
  console.debug('Intercom initialized')
}

export function bootIntercom(user: UserInfo) {
  if (import.meta.env.VITE_ENVIRONMENT === 'development') return

  boot({
    ...initOpts,
    user_id: user.id,
    name: user.first_name,
    email: user.email,
    created_at: Math.floor(new Date(user.date_joined).getTime() / 1000),
  })
  console.debug('Intercom booted with user data')
}

export function shutdownIntercom() {
  if (import.meta.env.VITE_ENVIRONMENT === 'development') return
  shutdown()
  console.debug('Intercom shut down')
}

// Not sure if necessary, but according to the Intercom documentation, this
// function can be called on SPAs when pages change to look for new messages.
// For now, it is unused, but in case we need it, a router hook would be good.
export function updateIntercom(user?: Partial<UserInfo>) {
  if (import.meta.env.VITE_ENVIRONMENT === 'development') return
  update(user ?? {})
  console.debug('Intercom updated with', user ?? {})
}
