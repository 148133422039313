<script setup lang="ts">
import { computed, watch } from 'vue'
import DropDown, { type DropdownPassThroughOptions } from 'primevue/dropdown'
import type { FormKitNode } from '@formkit/core'

export interface Props {
  options?: LabeledValue<string | boolean | null>[] | GroupedLabeledValue<string | null>[]
  placeholder?: string
  disabled?: boolean
  filter?: boolean
  grouped?: boolean
  filled?: boolean
  context?: {
    // for use as formkit element
    options: LabeledValue<string>[] | RecursiveLabeledValue<string>[]
    placeholder?: string
    disabled?: boolean
    filter?: boolean
    grouped?: boolean
    filled?: boolean
    node: FormKitNode
  }
}

export interface Events {
  (e: 'update', value: any): void
}

const props = defineProps<Props>()
const emit = defineEmits<Events>()
const selection = defineModel<any>()

const filled = computed(() => props.filled || props.context?.filled)

// this maps selection to formkit input value
if (props.context?.node.value) selection.value = props.context.node.value
watch(selection, () => {
  if (!props.context?.node) return
  props.context.node.input(selection.value)
})

const pt = computed<DropdownPassThroughOptions>(() => ({
  root: {
    class: [
      'h-10 mt-1 flex items-center relative p-2 transition duration-200',
      'border rounded-lg',
      'text-black dark:text-white',
      'cursor-pointer select-none',
      {
        'opacity-60 pointer-events-none cursor-default': props.disabled,
        'border-gray-border dark:border-gray-dark bg-white dark:bg-black': !filled.value,
        'border-gray-btn dark:border-gray-dark bg-gray-btn dark:bg-gray-dark/50': filled.value,
      },
    ],
  },
  input: {
    class: [
      'block flex-auto bg-transparent border-0 pr-2',
      {
        'text-black dark:text-white': typeof selection.value === 'string',
        'text-gray-light': typeof selection.value !== 'string' && !filled.value,
        'font-bold': filled.value,
      },
      'cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap appearance-none',
    ],
  },
  panel: {
    class:
      'max-w-[500px] absolute top-0 left-0 border-0 dark:border rounded-lg shadow-md bg-white dark:bg-black dark:text-white dark:border-white',
  },
  header: {
    class:
      'flex items-center justify-between py-3 px-5 m-0 border-b rounded-tl-md rounded-tr-md text-black dark:text-white bg-white-soft dark:bg-black-soft border-gray-border',
  },
  wrapper: {
    class: 'max-h-[200px] overflow-auto',
  },
  list: {
    class: 'py-3 list-none m-0',
  },
  item: ({ context }) => ({
    class: [
      'flex gap-2 items-center relative',
      'm-0 py-3 px-5',
      'border-0 transition-shadow duration-200 cursor-pointer hover:bg-gray/20',
      context.disabled ? 'cursor-default pointer-events-none text-gray' : 'cursor-pointer',
    ],
  }),
  filtercontainer: {
    class: 'relative w-full mx-2',
  },
  filterinput: {
    class:
      'font-sans leading-none pr-7 py-3 px-3 -mr-7 w-full text-black dark:text-white bg-white dark:bg-black-soft border-gray-border placeholder:text-gray dark:placeholder:text-gray-btn border rounded-lg appearance-none transition duration-200 focus:ring focus:outline-none focus:outline-offset-0 focus:ring-blue-400 dark:focus:ring-white-soft appearance-none',
  },
  filtericon: {
    class: 'absolute top-1/2 right-8 -mt-2',
  },
  loadingicon: {
    class: 'animate-spin',
  },
  clearicon: {
    class: 'text-black absolute top-1/2 right-12 -mt-2',
  },
  emptymessage: {
    class: ['leading-none', 'py-3 px-5', 'text-error', 'bg-transparent'],
  },
  itemGroup: {
    class: ['text-lg font-bold m-0 py-3 px-5 text-black dark:text-white cursor-auto'],
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
}))
</script>

<template>
  <DropDown
    :pt
    v-model="selection"
    optionLabel="label"
    optionValue="value"
    optionDisabled="disabled"
    :optionGroupLabel="grouped || context?.grouped ? 'label' : undefined"
    :optionGroupChildren="grouped || context?.grouped ? 'items' : undefined"
    :filter="filter || context?.filter"
    :options="options ?? context?.options ?? []"
    :placeholder="placeholder ?? context?.placeholder ?? ''"
    :disabled="disabled || context?.disabled"
    :filled
    @update:modelValue="emit('update', selection)"
    :key="selection"
  />
</template>
