<script setup lang="ts">
import Skeleton from 'primevue/skeleton'

interface Props {
  width?: string
  height?: string
  radius?: string
}

withDefaults(defineProps<Props>(), {  })

const pt = {
  root: {
    class: 'overflow-hidden animate-pulse rounded-full bg-gray/20',
  },
}
</script>

<template>
  <Skeleton :pt :width :height :borderRadius="radius" />
</template>
