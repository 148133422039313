<script setup lang="ts">
import { ref, computed } from 'vue'
import { useTasksStore } from '@store/tasks'
import { storeToRefs } from 'pinia'
import { isUserAllowedToEdit } from '@composable/useTasks'
import useLocales from '@composable/useLocales'
import { useRoute, useRouter } from 'vue-router'
import TaskEditSection from '@component/Tasks/TaskEditSection.vue'
import Dialog from '@component/NewDialog.vue'

const { LL } = useLocales()
const { deleteSelectedTask } = useTasksStore()
const { current } = storeToRefs(useTasksStore())
const isEdit = computed(() => (current.value ? isUserAllowedToEdit(current.value.assignee_id) : false))
const displayName = computed(() => {
  if (!current.value) return ''
  const user = current.value.assigneeUser
  return typeof user === 'object' ? user?.label : ''
})

const initials = computed(() => {
  if (!current.value) return ''
  const user = current.value.assigneeUser
  return typeof user === 'object' ? user?.meta.initials : ''
})

const showTaskDeleteDialog = ref(false)
function handleDeleteTask() {
  if (!current.value) return
  deleteSelectedTask(current.value.id)
  current.value = null
  showTaskDeleteDialog.value = false
}

const route = useRoute()
const router = useRouter()

async function handleClose() {
  if (route.query.taskId) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { taskId, ...newQuery } = route.query
    await router.push({
      query: newQuery,
    })
  }
  current.value = null
}
</script>

<template>
  <TaskEditSection
    v-if="current"
    :is-allowed-to-edit="isEdit"
    :task="current"
    :display-name="displayName as string"
    :initials
    :show-task-view-dialog="true"
    @cancel="handleClose"
    @delete="showTaskDeleteDialog = true"
  />
  <Dialog
    v-if="showTaskDeleteDialog"
    :header="LL.taskModal.deleteDialogTitle()"
    max-width="500px"
    static
    danger
    type="confirm"
    :confirm-label="LL.delete()"
    @cancel="showTaskDeleteDialog = false"
    @confirm="handleDeleteTask"
  >
    {{ LL.taskModal.deleteDialogText() }}
  </Dialog>
</template>

<style scoped></style>
