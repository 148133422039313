import { apiFetch, apiCreate } from '@api/client'
import type { ReportingStandard } from '@store/reporting/standards'
import type { ReportingRequirement } from '@/stores/reporting/requirements'

export interface MaterialityProcess {
  id: string
  name: string
  reporting_entity_id: string
  reporting_standard: ReportingStandard
  reporting_standard_id: number
  is_locked: boolean
  created_at: string
  modified_at: string
  description_business_model: string
  description_value_chain: string
  description_process_involved_persons: string
}

export interface DisclosureDraft {
  id: string
  reporting_requirement_id: 0
  reporting_requirement: ReportingRequirement
  user_input: string | null
  language: string
  response: string | null
  request_status: 'Waiting' | 'Successful' | 'Failed'
  user_response: 'Declined' | 'Accepted' | null
}

export type BatchMode = 'append' | 'replace' | 'skip'
export interface DisclosureDraftBatch {
  id: string
  author_id: string
  mode: BatchMode
  status: string
  report_id: string
  materiality_process_id: string
  materiality_process: MaterialityProcess
  disclosure_drafts: DisclosureDraft[]
  user_response: 'open' | 'closed'
}

export async function createDisclosureDraftBatch(
  processId: string,
  reportId: string,
  mode: 'append' | 'replace' | 'skip',
) {
  const url = `materiality/materiality-processes/${processId}/create-disclosure-draft-batch`
  return await apiCreate<DisclosureDraftBatch>(url, {
    materiality_process_id: processId,
    report_id: reportId,
    mode,
  })
}

export async function fetchDisclosureDraftBatches() {
  const url = 'disclosure-draft-batches'
  return await apiFetch<Paginated<DisclosureDraftBatch>>(url, {
    is_open: true,
    page_size: 100,
  })
}

export async function acceptDisclosureDraftBatch(batchId: string, overrides: any) {
  const url = `disclosure-draft-batches/${batchId}/accept`
  return await apiCreate(url, { overrides })
}

export async function declineDisclosureDraftBatch(batchId: string) {
  const url = `disclosure-draft-batches/${batchId}/decline`
  return await apiCreate(url, {})
}

// TODO: this endpoint does not exist, yet
export async function cancelDisclosureDraftBatch(batchId: string) {
  const url = `disclosure-draft-batches/${batchId}/cancel`
  return await apiCreate(url, {})
}
