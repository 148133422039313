<script setup lang="ts">import { ref as _ref } from 'vue';

import Img1 from '@/assets/images/onboarding/slide3-img1.svg?component'
import Img2 from '@/assets/images/onboarding/slide3-img2.svg?component'
import Img3 from '@/assets/images/onboarding/slide3-img3.svg?component'
import Img1_de from '@/assets/images/onboarding/slide3-img1-de.svg?component'
import Img2_de from '@/assets/images/onboarding/slide3-img2-de.svg?component'
import { onMounted } from 'vue'
import useLocales from '@composable/useLocales'

const { LL, locale } = useLocales()

let isImg1Animating = _ref(true)
let isImg2Animating = _ref(true)
let isImg3Animating = _ref(true)

onMounted(async () => {
  setTimeout(() => {
    isImg1Animating.value = false
    setTimeout(() => {
      isImg2Animating.value = false
      setTimeout(() => {
        isImg3Animating.value = false
      }, 1000)
    }, 1000)
  }, 1000)
})
</script>

<template>
  <div class="grid place-content-center justify-items-center h-full gap-10">
    <div class="flex gap-8">
      <div class="w-60 flex justify-center">
        <component :is="locale === 'de' ? Img1_de : Img1" :class="{ 'animate-move': isImg1Animating }" />
      </div>
      <div class="w-96">
        <h2 class="text-xl font-medium">{{ LL.onboarding.slide3.title1() }}</h2>
        <p>
          {{ LL.onboarding.slide3.text1_1() }}
        </p>
      </div>
    </div>
    <div class="flex gap-8 h-44">
      <div class="w-60 flex justify-center">
        <component
          :is="locale === 'de' ? Img2_de : Img2"
          :class="{ hidden: isImg1Animating, 'animate-move': isImg2Animating }"
        />
      </div>
      <div class="w-96">
        <h2 class="text-xl font-medium">{{ LL.onboarding.slide3.title2() }}</h2>
        <p class="mb-3">
          {{ LL.onboarding.slide3.text2_1() }}
        </p>
        <p>{{ LL.onboarding.slide3.text2_2() }}</p>
      </div>
    </div>
    <div class="flex gap-8 items-center h-36">
      <div class="w-60 flex justify-center">
        <Img3 :class="{ hidden: isImg2Animating, 'animate-move': isImg3Animating }" />
      </div>
      <div class="w-96">
        <p class="mb-3">{{ LL.onboarding.slide3.text3_1() }}</p>
        <p>{{ LL.onboarding.slide3.text3_2() }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
