import type { StaticMetricResponse } from '@api/gri'

export function isReferenceDataChange(
  metric: StaticMetricResponse,
  metricType: string,
  parsedReferenceValue: string,
  refItems: string[],
): string {
  if (metric && !['metric', 'location'].includes(metricType as string)) {
    if (metricType === 'value') {
      const val = Number(parsedReferenceValue)
      if (val !== metric.quantity?.value) {
        const refVals = refItems
        refVals[refVals.length - 1] = `last_value=${metric.quantity?.value}}}`
        return refVals.join('|')
      }
    } else if (metricType === 'label') {
      if (parsedReferenceValue !== metric.label) {
        const refVals = refItems
        refVals[refVals.length - 1] = `last_value=${metric.label}}}`
        return refVals.join('|')
      }
    } else {
      const unitVal = refItems[refItems.length - 1].replace(/\}}/g, '').split('=')[1]
      if (unitVal !== metric.quantity?.unit) {
        const refVals = refItems
        refVals[refVals.length - 1] = `last_value=${metric.quantity?.value}}}`
        return refVals.join('|')
      }
    }
  }
  return ''
}

export function getReferenceIndexes(
  value: string,
  type: string,
): null | { startIndexes: number[]; endIndexes: number[] } {
  if (value && type === 'rich-text') {
    let startIndex = value.indexOf('<code')
    let endIndex = value.indexOf('</code>')
    const endIndexOffset = '</code>'.length
    const startIndexes: number[] = []
    const endIndexes: number[] = []
    while (startIndex !== -1 && endIndex !== -1) {
      startIndexes.push(startIndex)
      endIndexes.push(endIndex + endIndexOffset)
      startIndex = value.indexOf('<code', endIndex + endIndexOffset)
      endIndex = value.indexOf('</code>', endIndex + endIndexOffset)
    }
    return {
      startIndexes,
      endIndexes,
    }
  }
  return null
}

export function getParsedLastValue(refString: string, units: Record<I18NUnit, (value: number) => string>): any {
  const refItems = refString.split('|')
  const last_value = refItems[refItems.length - 1].replace(/\}}/g, '').split('=')[1]
  const actualValue = !refItems.length
    ? ''
    : Object.keys(units).includes(last_value)
      ? units[last_value as I18NUnit](0)
      : last_value

  return actualValue
}

export function extractReferenceData(
  refString: string,
  units: Record<I18NUnit, (value: number) => string>,
): {
  dataset_ids: string[]
  start_date: string
  end_date: string
  reference_reporting_entity_id: string
  metric_key: string
  metric_type: string
  actualValue: string
  splitItems: string[]
} {
  const refItems = refString ? refString.split('|') : []
  const startDate = !refItems.length
    ? ''
    : (refItems.find((item) => item.includes('start_date')) as string)?.split('=')[1]
  const endDate = !refItems.length ? '' : (refItems.find((item) => item.includes('end_date')) as string)?.split('=')[1]

  const datasetIds = !refItems.length
    ? []
    : (refItems.find((item) => item.includes('dataset_ids')) as string)
        ?.split('=')[1]
        .replace(/\[/g, '')
        .replace(/\]/g, '')
        .split(',')

  const reference_reporting_entity_id = !refItems.length
    ? ''
    : (refItems.find((item) => item.includes('reference_reporting_entity_id')) as string)?.split('=')[1]

  const metricScopes = !refItems.length
    ? []
    : (refItems.find((item) => item.startsWith('metric')) as string)?.split('=')[1].split('_')

  const metricKey = metricScopes.length > 0 ? metricScopes[0].replace(/'/g, '') : ''
  const metricType = metricScopes.length > 0 ? metricScopes[1].replace(/'/g, '') : ''

  const last_value = refItems.length ? refItems[refItems.length - 1].replace(/\}}/g, '').split('=')[1] : ''
  const actualValue = !refItems.length
    ? ''
    : Object.keys(units).includes(last_value)
      ? units[last_value as I18NUnit](0)
      : last_value

  return {
    dataset_ids: datasetIds,
    start_date: startDate,
    end_date: endDate,
    reference_reporting_entity_id,
    metric_key: metricKey,
    metric_type: metricType,
    actualValue,
    splitItems: refItems,
  }
}
