import { P } from 'ts-pattern'

export function isArray<T = any>(x: any): x is Array<T> {
  return Array.isArray(x)
}

export function isObject(x: any): x is object {
  return x === Object(x) && !isArray(x) && typeof x !== 'function'
}

export function toCamelCase(s: string): string {
  return s.replace(/([-_][a-z])/gi, (p) => {
    return p.toLocaleUpperCase().replace('-', '').replace('_', '')
  })
}
export function toSnakeCase(s: string): string {
  return s.replace(/[A-Z]/g, (p) => {
    return `_${p.toLocaleLowerCase()}`
  })
}

export function camelCased(obj: any): typeof obj {
  if (isObject(obj)) {
    const n = {} as Record<string, any>
    Object.keys(obj).forEach((k) => {
      n[toCamelCase(k)] = camelCased((obj as Record<string, any>)[k])
    })
    return n
  }

  if (isArray(obj)) {
    return obj.map((i) => camelCased(i))
  }

  return obj
}

export function snakeCased(obj: any): typeof obj {
  if (isObject(obj)) {
    const n = {} as Record<string, any>
    Object.keys(obj).forEach((k) => {
      n[toSnakeCase(k)] = snakeCased((obj as Record<string, any>)[k])
    })
    return n
  }

  if (isArray(obj)) {
    return obj.map((i) => snakeCased(i))
  }

  return obj
}

export function success<T>(data: T): { success: true; data: T } {
  return { success: true, data }
}

export function failure<E>(error: E): { success: false; error: E } {
  return { success: false, error }
}

export function paginatedPattern(pattern: any) {
  return {
    count: P.number,
    previous: P.union(P.nullish, P.string),
    next: P.union(P.nullish, P.string),
    results: P.array(pattern),
  }
}
