<script setup lang="ts">
import { watch, computed } from 'vue'
import Toolbar from './RichText/Toolbar.vue'
import useRichText from '@composable/useRichText'
import type { FormKitFrameworkContext } from '@formkit/core'

const props = defineProps<{
  context?: FormKitFrameworkContext
  disabled?: boolean
}>()

// Optional<T> defines as T|null, which is what we get from the backend,
// instead of actual optional values (T|undefined)
const value = defineModel<Optional<string>>({ required: false })
const isDisabled = computed(() => props.disabled ?? props.context?.disabled ?? false)

const { editor, EditorContent, command, focussed } = useRichText({
  content: value.value ?? props.context?._value,
  // placeholder: props.context.options,
  onUpdate: (newValue) => {
    if (props.context) props.context.node.input(newValue)
    value.value = newValue
  },
})

watch(
  isDisabled,
  (isDisabled, wasDisabled) => {
    if (isDisabled === wasDisabled) return
    editor.setEditable(!isDisabled)
    if (isDisabled) editor.chain().blur().run()
  },
  { immediate: true },
)
</script>

<template>
  <div
    class="w-full min-h-[10em] flex flex-col gap-2 border rounded-lg dark:bg-gray-mid"
    :class="[focussed ? 'border-blue' : 'border-gray-light']"
    @click="editor.chain().focus().run()"
  >
    <Toolbar v-bind="{ editor, command, disabled: isDisabled }" />
    <EditorContent :editor="editor" class="block px-4 h-full overflow-y-auto" />
  </div>
</template>
