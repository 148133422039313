<script setup lang="ts">const isStatic = __MACROS_toRef(__props, "static");

import { toRef as __MACROS_toRef } from "vue";
import { ref, computed } from 'vue'
import type { FormKitNode } from '@formkit/core'

import AppButton from '@component/forms/AppButton.vue'
import TextField from '@component/forms/TextField.vue'
import { FormKitIcon } from '@formkit/vue'
import useLocales from '@composable/useLocales'

export type DialogType = 'confirm' | 'prompt' | 'info'

export interface Props {
  modelValue?: boolean
  static?: boolean
  header?: string
  headerIcon?: string
  body?: string
  /// one of confirm, danger or info
  type?: DialogType
  /// hide the header completely (will not render slot, header, headerIcon and closeIcon)
  /// render red confirm button for dangerous actions
  danger?: boolean
  noHeader?: boolean
  /// hide the footer completely (will not render slot or any buttons)
  noFooter?: boolean
  /// override confirm button label
  confirmLabel?: string
  /// override cancel button label
  cancelLabel?: string
  /// only allow confirmation if verify function returns true
  verify?: (value?: string, node?: FormKitNode) => boolean
  /// label for prompt input
  promptLabel?: string
  promptDefault?: string
  /// additional options to pass to input field when dialog type is prompt
  promptAttrs?: any
  maxWidth?: string
  width?: string
  height?: string
  bgColor?: string
  textColor?: string
  closeOnOutsideClick?: boolean
}

withDefaults(defineProps<Props>(), { modelValue: true,header: 'Info',type: 'info',closeOnOutsideClick: false, })

/// Dialog emits close whenever closed, cancel or confirm on respective button
/// presses. This means, after cancel is pressed, first 'cancel', then 'close'
/// will be fired. The same is true for the confirm button.
const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'confirm', value?: string): void
  (e: 'close'): void
  (e: 'update:modelValue', value: boolean): void
}>()

const { LL } = useLocales()

const computedConfirmLabel = computed(() => __props.confirmLabel ?? LL.value.confirm())
const computedCancelLabel = computed(() => __props.cancelLabel ?? LL.value.cancel())

const showCancel = computed(() => __props.type === 'confirm' || __props.type === 'prompt')

type CancelConfirm = {
  cancel?: boolean
  confirm?: boolean
}

const promptField = ref<FormKitNode | undefined>()

const canConfirm = computed(() => {
  const value = promptField.value?.context?.value ?? ''
  if (__props.verify !== undefined) return __props.verify(value, promptField.value)
  if (__props.type !== 'prompt') return true // by default, always true

  return Boolean(promptField.value?.context?.state.valid)
})

function close({ cancel, confirm }: CancelConfirm = { cancel: false, confirm: false }) {
  if (confirm) emit('confirm', promptField.value?.context?.value || '')
  if (cancel) emit('cancel')
  emit('update:modelValue', false)
  emit('close')
}

function handleOutsideClick() {
  if (__props.closeOnOutsideClick && !__props.static) close()
}
</script>

<template>
  <transition name="fade">
    <div
      v-if="modelValue"
      class="fixed inset-0 flex items-center justify-center bg-black/40 dark:bg-white-40 z-50"
      @click.self="handleOutsideClick"
    >
      <div
        class="flex flex-col max-h-[95vh] p-4 border-2 rounded-lg shadow-xl overflow-x-hidden overflow-y-auto"
        :class="[bgColor ?? 'bg-white border-white dark:bg-black-soft', textColor ?? 'text-black dark:text-white']"
        :style="{ width, height, maxWidth }"
        data-cy="general__dialog-message"
      >
        <header class="flex justify-between items-start" v-if="!noHeader">
          <FormKitIcon v-if="headerIcon" class="w-4 ml-4 dark:text-white" :icon="headerIcon" />
          <div v-if="header" class="w-fit text-lg font-bold">{{ header }}</div>
          <slot name="header" v-else>&nbsp;</slot>
          <FormKitIcon
            v-if="!static"
            class="w-8 rounded-md bg-gray-btn cursor-pointer dark:text-black z-50"
            icon="dialogClose"
            @click="close()"
          />
        </header>

        <div class="w-full h-full">
          <p v-if="body" class="text-justify">{{ body }}</p>
          <slot v-else>
            <TextField
              v-if="type === 'prompt'"
              @node="promptField = $event"
              required
              :label="promptLabel"
              :model-value="promptDefault"
              v-bind="{ ...promptAttrs }"
            />
          </slot>
        </div>

        <footer class="flex gap-2 justify-between items-center" :class="{ 'mt-5': !noFooter }">
          <slot name="footer" v-if="!noFooter">
            <AppButton
              v-if="showCancel"
              secondary
              tab-btn
              :label="computedCancelLabel"
              @click="close({ cancel: true })"
              data-cy="general__cancel-button"
            />
            <AppButton
              :primary="!danger"
              :disabled="!canConfirm"
              :danger="danger"
              :label="computedConfirmLabel"
              @click="close({ confirm: true })"
              data-cy="general__ok-button"
            />
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>
