import { isRef, type Ref } from 'vue'
import useLocales from './useLocales'
import { formatNumber, meaningfulUnit } from '../util/formatting'

export type DateStyle = 'long' | 'short' | 'full' | 'medium'

export function useFormattedNumber() {
  const { locale } = useLocales()

  function format(n: number | Ref<number>, unit = '', maximumFractionDigits = 2) {
    const value = isRef(n) ? n.value : n
    const minimumFractionDigits = maximumFractionDigits < 2 ? maximumFractionDigits : 2
    return meaningfulUnit(value, unit, locale.value, {
      minimumFractionDigits,
      maximumFractionDigits,
    })
  }

  function currency(n: number | Ref<number>, currency: string, maximumFractionDigits = 2) {
    const value = isRef(n) ? n.value : n
    return formatNumber(value, locale.value, {
      style: 'currency',
      currency,
      maximumFractionDigits,
    })
  }

  function localeDate(d: string | Date, dateStyle: DateStyle = 'long') {
    return new Date(d).toLocaleString(locale.value, {
      dateStyle,
    })
  }

  function localeTime(d: string | Date, timeStyle: DateStyle = 'long') {
    return new Date(d).toLocaleString(locale.value, {
      timeStyle,
    })
  }

  function localeDateTime(d: string | Date, dateStyle: DateStyle = 'long', timeStyle: DateStyle = 'long') {
    return new Date(d).toLocaleString(locale.value, {
      timeStyle,
      dateStyle,
    })
  }

  return { format, currency, localeDate, localeTime, localeDateTime }
}
